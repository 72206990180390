import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { BASE_URL } from "../../services/utils/apiRessources";

const initialState = {
    id: null,
    contentUrl: null,
    file: null,
    fileIdsList: [],
    isLoading: false,
    isError: null,
    isSuccess: false,
};

const docSlice = createSlice({
    name: "file",
    initialState,
    reducers: {
        populateFile: (state, action) => {
            state.id = action.payload.id;
            state.contentUrl = action.payload.contentUrl;
        },
        populateFileIdsList: (state, action) => {
            state.fileIdsList.push(action.payload);
        },
        unselectFile: () => initialState,
        unselectFileIdsList: (state, _action) => {
            state.fileIdsList = [];
        },
        setIsLoadingState: (state, action) => {
            state.isLoading = action.payload.isLoading;
        },
        setIsErrorState: (state, action) => {
            state.isError = action.payload.isError;
        },
        setIsSuccessState: (state, action) => {
            state.isSuccess = action.payload.isSuccess;
        },
    },
});

export const uploadFile = (formData) => (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
        .post(`${BASE_URL}/api/media_objects`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            toast.success("Vos fichiers ont été sauvegardés avec succès.");
            dispatch(populateFileIdsList(res.data.id.toString()));
            dispatch(setIsLoadingState({ isLoading: false }));
        })
        .catch((_err) => {
            toast.error("Une erreur s'est produite lors de l'envoi de fichier");
            dispatch(unselectFileIdsList());
            dispatch(setIsLoadingState({ isLoading: false }));
        });
};

export const selectFileId = (state) => {
    return state.file.id;
};

export const selectFileIdsList = (state) => {
    return state.file.fileIdsList;
};


export const selectIsErrorState = (state) => {
    return state.file.isError;
};

export const selectIsLoadingState = (state) => {
    return state.file.isLoading;
};

export const {
    populateFile,
    populateFileIdsList,
    unselectFile,
    unselectFileIdsList,
    setIsLoadingState,
    setIsErrorState,
    setIsSuccessState,
} = docSlice.actions;

export default docSlice.reducer;
