import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/loginPage/LoginPage";
import ResetPasswordPage from "./pages/resetPasswordPage/ResetPasswordPage";
import PrivateRoute from "./routes/auth/PrivateRoute";
import ChangePasswordPage from "./pages/changePasswordPage/ChangePasswordPage";
import HomePage from "./pages/homePage/HomePage";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import FirstLoginPage from "./pages/FirstLogin/FirstLoginPage";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            path="/first-login"
            element={
              <PrivateRoute>
                <FirstLoginPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password/:key"
            element={<ChangePasswordPage />}
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
