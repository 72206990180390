import { combineReducers } from "redux";

import user from "./slice/UserSlice";
import holiday from "./slice/HolidaySlice";
import typeOfLeave from "./slice/TypeOfLeaveSlice";
import Leave from "./slice/LeaveSlice";
import typeOfDoc from './slice/typeOfDocSlice';
import doc from './slice/docSlice';
import teams from './slice/teamsSlice';
import authorization from './slice/AuthorizationSlice';
import notification from './slice/NotificationSlice';
import file from "./slice/fileSlice";

const reducers = combineReducers({
    user,
    holiday,
    typeOfLeave,
    Leave,
    typeOfDoc,
    doc,
    teams,
    authorization,
    notification,
    file,
});

export default reducers;
