import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { sendDocRequest, selectIsSuccessState } from "../../redux/slice/docSlice";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../redux/slice/UserSlice";
import { fetchTypesOfDoc, selectTypesOfDoc } from "../../redux/slice/typeOfDocSlice";
import { uploadFile, selectFileIdsList, unselectFileIdsList } from "../../redux/slice/fileSlice";
import DropFileInput from "../fileUploadBox/DorpFileInput";

import "bootstrap/dist/css/bootstrap.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import "./docRequestForm.sass";
import "../../styles/globalStyle.sass";

export const DocRequestForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const docsTypes = useSelector(selectTypesOfDoc);
  const isSuccess = useSelector(selectIsSuccessState);
  const userId = useSelector(selectUserId);
  const fileIds = useSelector(selectFileIdsList);
  const [comment, setComment] = useState("");
  const [docType, setDocType] = useState('');
  const [files, setFiles] = useState([]);
  const typeSelected = docsTypes.find(type => type.id === parseInt(docType));

  useEffect(() => {
    dispatch(fetchTypesOfDoc(true));
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/my-documents");
    }
  }, [isSuccess, navigate]);

  const uploadFiles = (arr) => {
    const formData = new FormData();

    arr.forEach((file) => {
      formData.append("file", file);
      dispatch(
        uploadFile(formData)
      );
    });
    setFiles([]);
  };

  const confirmDocRequest = () => {
    if (fileIds.length === 0) {
      dispatch(
        sendDocRequest({
          typeOfDoc: docType,
          user: userId,
          comment: comment,
        })
      );
    } else {
      dispatch(
        sendDocRequest({
          typeOfDoc: docType,
          user: userId,
          comment: comment,
        }, fileIds, "JUSTIFICATION")
      );
      dispatch(unselectFileIdsList())
    }

  };

  return (
    <div className="docFormPage">
      <div className="typeDocSelect">
        <div className="row labelDoc">
          1. Type de Document
        </div>
        <div className="row selectSpace">
          <select
            name="typeOfDoc"
            id="typeOfDoc"
            className="form-select"
            value={docType}
            onChange={(e) => setDocType(e.target.value)}
            required
          >
            <option value="">Sélectionnez un type de document</option>
            {docsTypes?.map((documentType) => (
              <option key={documentType.id} value={documentType.id}>
                {documentType.type}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="textSpace">
        <div className="row labelDoc" div>
          2. Justificatif & Motif &nbsp; ({comment.length}/250)
        </div>
        <div className="row inputSpace">

          <InputTextarea
            name="commentary"
            id="commentary"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={5}
            cols={60}
            placeholder="Commentaire"
            autoResize
          />
        </div>
      </div>
      <div className="uploadBox-wrapper">
        <div className="uploadBox">
          <DropFileInput
            onFileChange={(fileObjs) => setFiles(fileObjs)}
          />
        </div>
      </div>
      <div className="submitButton">
        {files.length !== 0 && fileIds.length === 0 && <button
          className="btn btn-outline-secondary mr-4"
          style={{
            borderRadius: "3rem",
            height: "3rem",
            width: "3rem",
          }}
          onClick={() => uploadFiles(files)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
          </svg>
        </button>}
        <button
          data-bs-toggle="modal"
          data-bs-target="#SubmitDocRequest"
          className={docType === ""? "btn btn-secondary" : "btn btn-primary"}
          disabled={docType === "" || (files.length !== 0 && files.length !== fileIds.length)}
        >
          Envoyer
        </button>
        <div
          className="modal fade"
          id="SubmitDocRequest"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body mt-2 mb-2">
                <div className="h3-Montserrat mb-4 text-center">Souhaitez vous confirmer votre demande de Document </div>
                <div
                  className="text-center mb-4"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '17px',
                    lineHeight: '141%',

                  }}>
                  <div
                    style={{
                      padding: '0.5rem 1rem',
                    }}>Demande d'une</div>
                  <div
                    style={{
                      background: '#F2F2F4',
                      borderRadius: '4px',
                      padding: '0.5rem 1rem',
                    }}
                  >{typeSelected?.type}</div>
                </div>
                <div
                  style={{
                    fontFamily: 'Montserrat',
                    fontStyle: 'italic',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '145%',
                  }} className="text-center">NB: Vous pouvez annuler en cliquant sur la demande pendant qu'elle est en cours.</div>
              </div>
              <div className="modal-footer d-flex col justify-content-evenly">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={confirmDocRequest}
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
export default DocRequestForm;
