import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTreatedAuthorizationRequests,
  fetchTreatedReceivedAuthorizationRequests,
} from "../../redux/slice/AuthorizationSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import {
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import Paginator from "../paginator/Paginator";
import ModalContent from "./ModalContent";

const TreatedReceivedAuthorizationRequests = ({
  treatedReceiveaAuthorizationRequests,
  roles,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  const clickPagination = (page) => {
    if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) {
      dispatch(fetchAllTreatedAuthorizationRequests(page));
    } else if (userId) {
      dispatch(fetchTreatedReceivedAuthorizationRequests(userId, page));
    }
  };
  return (
    <>
      {treatedReceiveaAuthorizationRequests[HYDRA_MEMBER]?.length > 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">De</th>
                <th scope="col">Date</th>
                <th scope="col">Temps</th>
                <th scope="col">Etat</th>
              </tr>
            </thead>
            <tbody>
              {treatedReceiveaAuthorizationRequests[HYDRA_MEMBER]?.map(
                (obj, key) => (
                  <tr
                    key={key}
                    data-bs-toggle="modal"
                    data-bs-target={`#staticBackdrop${key}treatedAuthorizations`}
                    className="tratedHover"
                  >
                    <td className="name_column">
                      <div className="cell_name">
                        {obj.user.firstName} {obj.user.lastName}
                      </div>
                    </td>
                    <td>
                      {dateHelpers.formatDateToFrenchFormat(
                        obj.startDate.split("T")[0]
                      )}
                    </td>
                    <td>
                      {dateHelpers.getTimeFromDate(obj.startDate, true)} -&nbsp;
                      {dateHelpers.getTimeFromDate(obj.endDate, true)}
                    </td>
                    {obj.finalStatus === "ACCEPTED" && (
                      <td className="response_wrapper">
                        <div className="accepted">Acceptée</div>
                      </td>
                    )}
                    {obj.finalStatus === "REJECTED" && (
                      <td className="response_wrapper">
                        <div className="rejected">Refusé</div>
                      </td>
                    )}
                    {obj.finalStatus === "CANCELED" && (
                      <td className="response_wrapper">
                        <div className="rejected">Annulé</div>
                      </td>
                    )}
                    <td
                      className="modal fade"
                      id={`staticBackdrop${key}treatedAuthorizations`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <ModalContent obj={obj} notTreated={false} />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <Paginator
            totalItems={treatedReceiveaAuthorizationRequests[HYDRA_TOTALITEMS]}
            paginate={clickPagination}
          />
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas encore des demandes traitées
        </div>
      )}
    </>
  );
};

export default TreatedReceivedAuthorizationRequests;
