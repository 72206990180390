import React from "react";
import { NavLink } from "react-router-dom";
import MyRequestsRoute from "../../routes/MyRequestsRoute";

const MyRequestsPage = () => {
  return (
    <div className="validationPage-wrapper">
      <div className="validationPage-header">
        <div className="title">Vos demandes</div>
        <div
          className="btn-group rounded-pill switch"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio1"
            autoComplete="off"
            readOnly
          />          
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio2"
            autoComplete="off"
            readOnly
          />
        </div>
      </div>
      <div className="validations-routes">
        <MyRequestsRoute />
      </div>
    </div>
  );
};

export default MyRequestsPage;
