import React from "react";
import { dateHelpers } from "../../services/utils/dateHelpers";
import LeaveRequestStepsPopup from "../leaveRequestStepsPopUp/LeaveRequestStepsPopup";

const MyLeavesListTable = ({ myLeavesList }) => {
  return (
    <>
      {myLeavesList?.length !== 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Date</th>
                <th scope="col">Durée</th>
                <th scope="col">État</th>
              </tr>
            </thead>
            <tbody>
              {myLeavesList?.map(
                (obj, key) =>
                  key < 5 &&
                  obj.finalStatus === "IN_PROGRESS" && (
                    <tr key={key}>
                      <td>{obj.typeOfLeave.type}</td>
                      <td>
                        {`${dateHelpers.formatDateToFrenchFormat(
                          obj.startDate.split("T")[0]
                        )} - ${dateHelpers.formatDateToFrenchFormat(
                          obj.endDate.split("T")[0]
                        )}`}
                      </td>
                      <td>{obj.notPaidDuration}j</td>
                      {obj.finalStatus === "IN_PROGRESS" && (
                        <td className="response_wrapper">
                          <div className="inProgress">En cours</div>
                          <LeaveRequestStepsPopup askForLeave={obj}/>
                        </td>
                      )}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas des demandes en cours
        </div>
      )}
    </>
  );
};

export default MyLeavesListTable;
