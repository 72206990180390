import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Switch from "@mui/material/Switch";

import {
  fetchTypesOfDoc,
  selectTypesOfDoc,
  changeIsEnableState,
  deleteTypeOfDoc,
  addTypeOfDoc,
} from "./../../redux/slice/typeOfDocSlice";


import "./typeOfDoc.sass";

const TypeOfDoc = () => {
  const dispatch = useDispatch();
  const typeOfDocList = useSelector(selectTypesOfDoc);
  const [typeOfDoc, setTypeOfDoc] = useState("");

  useEffect(() => {
    dispatch(fetchTypesOfDoc(false));
  }, [dispatch]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: typeOfDoc,
    };
    dispatch(addTypeOfDoc(data));
  };

  return (
    <div className="TypeOfDoc-wrapper">
      <div className="TypeOfDoc-form">
        <div className="form">
          <div className="mb-3">
            <label>1. Type de document</label>
          </div>
          <input
            name="type"
            type="text"
            id="type"
            className="form-control mb-3"
            placeholder="Type de document"
            onChange={(e) => {setTypeOfDoc(e.target.value)}}
            value={typeOfDoc}
          />
          <button
            className={`btn ${typeOfDoc.length > 1 ? "btn-primary" : "btn-secondary"}`}
            data-bs-toggle="modal"
            data-bs-target="#addTypeOfDoc"
            disabled={typeOfDoc.length < 1}
          >
            Ajouter un type de document
          </button>
        </div>
      </div>
      <div className="TypeOfDoc-list">
        <table className="table  table-borderless">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Activé</th>
              <th scope="col">Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {typeOfDocList?.map((obj, key) => (
              <tr key={key}>
                <td>{obj.type}</td>
                <td>
                  <Switch
                    checked={obj.isEnable}
                    onClick={() => {
                      dispatch(
                        changeIsEnableState({ isEnable: !obj.isEnable },obj.id)
                      );
                    }}
                  />
                </td>
                <td
                  className="deleteIcon"
                  data-bs-toggle="modal"
                  data-bs-target={`#deleteTypeOfDoc${obj.id}`}
                >
                  <DeleteForeverIcon />
                </td>
                <td
                  className="modal fade"
                  id={`deleteTypeOfDoc${obj.id}`}
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <h6>Vous êtes sûr de supprimer un type de document ?</h6>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          data-bs-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            dispatch(deleteTypeOfDoc(obj.id));
                          }}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        className="modal fade"
        id="addTypeOfDoc"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h6>Vous êtes sûr d'ajouter un type de document ?</h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  handleFormSubmit(e);
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeOfDoc;
