import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import {
  BASE_URL,
  ERROR_MESSAGE,
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";

const initialState = {
  id: null,
  receiver: null,
  content: null,
  isSeen: null,
  mercureHubUrl: null,
  notificationsList: {},
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    populateNotification: (state, action) => {
      state.id = action.payload.id;
      state.receiver = action.payload.receiver;
      state.content = action.payload.content;
      state.isSeen = action.payload.isSeen;
    },
    unselectNotification: () => initialState,
    setNotificationsList: (state, action) => {
      state.notificationsList = action.payload.notificationsList;
    },
    setMercureHubUrl: (state, action) => {
      state.mercureHubUrl = action.payload.mercureHubUrl;
    },
    resetCounter: (state) => {
      state.notificationsList[HYDRA_TOTALITEMS] = 0;
    },
    updateNotificationItemInList: (state, action) => {
      const updatedItemIndex = state.notificationsList[HYDRA_MEMBER].findIndex(
        (NotificationItem) =>
          NotificationItem.id === action.payload.updatedNotification.id
      );
      if (updatedItemIndex !== -1) {
        state.notificationsList[HYDRA_MEMBER][updatedItemIndex] =
          action.payload.updatedNotification;          
      } else {
        state.notificationsList[HYDRA_MEMBER].unshift(
          action.payload.updatedNotification
        );
        state.notificationsList[HYDRA_TOTALITEMS]++;
      }
    },
  },
});

const errorMsg = () => {
  return toast.error(ERROR_MESSAGE);
};

export const fetchNotSeenNotifications = (userId) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/notifications?receiver.id=${userId}&isSeen=0`)
    .then((res) => {
      const matches = res.headers.link.match(
        /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
      );
      dispatch(
        setMercureHubUrl({
          mercureHubUrl: Array.isArray(matches) ? matches[1] : null,
        })
      );
      dispatch(setNotificationsList({ notificationsList: res.data }));
    })
    .catch(() => {
      errorMsg();
    });
};

export const addNotification = (item) => (dispatch) => {
  const payload = {
    content: item.content,
    createdAt: item.createdAt,
    id: item.id,
    isSeen: item.isSeen,
    receiver: item.receiver,
  };
  dispatch(updateNotificationItemInList({ updatedNotification: payload }));
};

export const resetNotificationCounter = () => (dispatch) => {
  dispatch(resetCounter());
};

export const markNotificationAsSeen = (id) => (dispatch) => {
  axios
    .put(`${BASE_URL}/api/notifications/${id}`, { isSeen: true })
    .then((res) => {
      dispatch(updateNotificationItemInList({ updatedNotification: res.data }));
    })
    .catch(() => {
      errorMsg();
    });
};

export const selectNotificationsList = (state) => {
  return state.notification.notificationsList;
};

export const selectMercureHubUrl = (state) => {
  return state.notification.mercureHubUrl;
};

export const {
  populateNotification,
  unselectNotification,
  setNotificationsList,
  updateNotificationItemInList,
  setMercureHubUrl,
  resetCounter,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
