import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import CosaLogo from "../../assets/CosaLogo";
import {
  dispatchResetPassword,
  selectIsLoadingState,
  selectIsSuccessState,
  selectIsErrorState,
} from "../../redux/slice/UserSlice";

import "./resetPasswordPage.sass";

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoadingState = useSelector(selectIsLoadingState);
  const isErrorState = useSelector(selectIsErrorState);
  const isSuccessState = useSelector(selectIsSuccessState);

  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    setEmailError(false);
    setCredentials({ ...credentials, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (credentials.email.endsWith("@cosavostra.com")) {
      setEmailError(false);
      dispatch(dispatchResetPassword(credentials));
    } else {
      setEmailError(true);
    }
  };

  useEffect(() => {
    if (isSuccessState || isErrorState) {
      toast.success(
        "Merci de vérifier votre Email pour modifier votre mot de passe"
      );
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }, [isErrorState, isSuccessState, navigate]);

  useEffect(() => {
    setLoader(isLoadingState);
  }, [isLoadingState]);

  useEffect(() => {
    setLoader(false);
  }, []);

  return (
    <div className="resetPassword">
      <div
        className="logo_wrapper"
        onClick={() => {
          navigate("/login");
        }}
      >
        <CosaLogo />
        <div className="logo_text">
          <h1>CosaRH</h1>
          <div>Made with ❤️ by CosaVostra</div>
        </div>
      </div>
      <div className="resetPassword-form-card">
        <div className="card">
          <div className="card-body p-3">
            <div className="input-wrapper">
              <form onSubmit={handleFormSubmit}>
                <div className="mb-3 email_wrapper">
                  <label className="form-label d-flex justify-content-start">
                    Veuillez saisir votre adresse e-mail  
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    className="form-control"
                    aria-describedby="emailHelp"
                    required
                  />
                  {emailError ? (
                    <div className="alert alert-danger mt-2" role="alert">
                      Vous devez avoir une adresse Email cosavostra
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="login_auth_wrapper">
                  <button className="reset-password-button">Réinitialiser le mot de passe</button>
                </div>
              </form>
            </div>
            {loader ? (
              <div
                className="spinner-border text-primary mt-3"
                role="status"
              ></div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
