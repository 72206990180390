import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import {
  BASE_URL,
  ERROR_MESSAGE,
  HYDRA_MEMBER,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import { buildFilters } from "../../services/utils/filterHelpers";
import { dispatchUser } from "./UserSlice";

const initialState = {
  id: null,
  startDate: null,
  endDate: null,
  commentary: null,
  duration: null,
  finalStatus: null,
  treated: null,
  authorizationList: [],
  myAuthorizationList: [],
  myTreatedAuthorizationList: {},
  notTreatedReceivedAuthorizationRequests: {},
  treatedReceivedAuthorizationRequests: {},
  isLoading: false,
  isError: null,
  isSuccess: false,
};

const AuthorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {
    populateAuthorization: (state, action) => {
      state.id = action.payload.id;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.commentary = action.payload.commentary;
      state.duration = action.payload.commentary;
      state.finalStatus = action.payload.finalStatus;
      state.treated = action.payload.treated;
    },
    setDuration: (state, action) => {
      state.duration = action.payload.duration;
    },
    setAuthorizationList: (state, action) => {
      state.authorizationList = action.payload.authorizationList;
    },
    setNotTreatedReceivedAuthorizationRequests: (state, action) => {
      state.notTreatedReceivedAuthorizationRequests =
        action.payload.notTreatedReceivedAuthorizationRequests;
    },
    setTreatedReceivedAuthorizationRequests: (state, action) => {
      state.treatedReceivedAuthorizationRequests =
        action.payload.treatedReceivedAuthorizationRequests;
    },
    populateMyAuthorizationList: (state, action) => {
      state.myAuthorizationList = action.payload.myAuthorizationList;
    },
    treatAuthorizationItemInLists: (state, action) => {
      if (action.payload.roles.includes("ROLE_MANAGER") || action.payload.roles.includes("ROLE_ADMIN")) {
        state.treatedReceivedAuthorizationRequests[HYDRA_MEMBER].unshift(
          action.payload.treatedReceivedAuthorizationRequests
        );

        const notTreatedRequestAuthorizationIndex =
          state.notTreatedReceivedAuthorizationRequests[HYDRA_MEMBER].findIndex(
            (item) =>
              item.id === action.payload.treatedReceivedAuthorizationRequests.id
          );
        if (notTreatedRequestAuthorizationIndex !== -1) {
          state.notTreatedReceivedAuthorizationRequests[HYDRA_MEMBER].splice(
            notTreatedRequestAuthorizationIndex,
            1
          );
        }
      }
    },
    updateAuthorizationItemInMyList: (state, action) => {
      const { updatedAuthorizationRequest } = action.payload;

      const updatedItemIndex = state.myTreatedAuthorizationList[HYDRA_MEMBER].findIndex(
        (authorization) => authorization.id === updatedAuthorizationRequest.id
      );
      if (updatedItemIndex !== -1) {
        state.myTreatedAuthorizationList[HYDRA_MEMBER][updatedItemIndex] =
        updatedAuthorizationRequest;
      }
    },
    setMyTreatedAuthorizationList: (state, action) => {
      state.myTreatedAuthorizationList =
        action.payload.myTreatedAuthorizationList;
    },
    setIsLoadingState: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsErrorState: (state, action) => {
      state.isError = action.payload.isError;
    },
    setIsSuccessState: (state, action) => {
      state.isSuccess = action.payload.isSuccess;
    },
    unselecAuthorization: () => initialState,
  },
});

const errorMsg = () => {
  return toast.error(ERROR_MESSAGE);
};

export const sendAuthorizationRequest = (data) => (dispatch) => {
  const obj = {
    startDate: dateHelpers.formatDate(data.startDate),
    endDate: dateHelpers.formatDate(data.endDate),
    commentary: data.commentary,
    user: data.user,
  };

  axios
    .post(`${BASE_URL}/api/ask_for_authorizations`, obj)
    .then(() => {
      dispatch(dispatchUser());
      toast.success("votre demande a été envoyée avec succés");
    })
    .catch(() => {
      errorMsg();
    });
};

export const fetchAuthorizationRequestResume = (data) => (dispatch) => {
  dispatch(setIsErrorState({ isError: null }));
  dispatch(setIsLoadingState({ isLoading: true }));
  const obj = {
    startDate: dateHelpers.formatDate(data.startDate),
    endDate: dateHelpers.formatDate(data.endDate),
    commentary: data.commentary,
    user: data.user,
  };
  axios
    .post(`${BASE_URL}/api/ask_for_authorisations/check_resume`, obj)
    .then((res) => {
      dispatch(
        setDuration({
          duration: res.data,
        })
      );
      dispatch(setIsLoadingState({ isLoading: false }));
    })
    .catch((err) => {
      dispatch(
        setIsErrorState({ isError: err.response.data["hydra:description"] })
      );
      dispatch(setIsLoadingState({ isLoading: false }));
    });
};

export const fetchMyAuthorizationList =
  (queryParams = {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(`${BASE_URL}/api/ask_for_authorizations${buildFilters(queryParams)}`)
      .then((res) => {
        dispatch(
          populateMyAuthorizationList({
            myAuthorizationList: res.data[HYDRA_MEMBER],
          })
        );
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
        dispatch(setIsLoadingState({ isLoading: false }));
      });
  };

export const fetchMyTreatedAuthorizationRequests =
  (userId, page = 1) =>
  (dispatch) => {
    axios
      .get(
        `${BASE_URL}/api/ask_for_authorizations?user.id=${userId}&finalStatus[]=REJECTED&finalStatus[]=ACCEPTED&finalStatus[]=CANCELED&page=${page}`
      )
      .then((res) => {
        dispatch(
          setMyTreatedAuthorizationList({
            myTreatedAuthorizationList: res.data,
          })
        );
      })
      .catch(() => {
        errorMsg();
      });
  };

export const fetchNotTreatedAuthorizationRequests =
  (page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_authorizations?finalStatus[]=IN_PROGRESS&page=${page}`
      )
      .then((res) => {
        const data = {
          notTreatedReceivedAuthorizationRequests: res.data,
        };
        dispatch(setNotTreatedReceivedAuthorizationRequests(data));
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
        dispatch(setIsLoadingState({ isLoading: false }));
      });
  };

export const fetchTreatedReceivedAuthorizationRequests =
  (userId, page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_authorizations?allowedResponders=:${userId};&finalStatus[]=ACCEPTED&finalStatus[]=REJECTED&finalStatus[]=CANCELED&page=${page}`
      )
      .then((res) => {
        const data = {
          treatedReceivedAuthorizationRequests: res.data,
        };
        dispatch(setTreatedReceivedAuthorizationRequests(data));
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
      });
  };

export const fetchAllTreatedAuthorizationRequests =
  (page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_authorizations?&finalStatus[]=REJECTED&finalStatus[]=ACCEPTED&finalStatus[]=CANCELED&page=${page}`
      )
      .then((res) => {
        dispatch(
          setTreatedReceivedAuthorizationRequests({
            treatedReceivedAuthorizationRequests: res.data,
          })
        );
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
        dispatch(setIsLoadingState({ isLoading: false }));
      });
  };

export const fetchNotTreatedReceivedAuthorizationRequests =
  (userId, page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_authorizations?allowedResponders=:${userId};&finalStatus[]=IN_PROGRESS&page=${page}`
      )
      .then((res) => {
        dispatch(
          setNotTreatedReceivedAuthorizationRequests({
            notTreatedReceivedAuthorizationRequests: res.data,
          })
        );
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
        dispatch(setIsLoadingState({ isLoading: false }));
      });
  };

export const cancelAcceptedAuthorizationRequest = (id) => (dispatch) => {
  dispatch(setIsLoadingState({ isLoading: true }));
  const obj = {
    finalStatus: "CANCELED",
  };
  axios
    .put(`${BASE_URL}/api/ask_for_authorizations/${id}`, obj)
    .then((res) => {
      dispatch(
        updateAuthorizationItemInMyList({
          updatedAuthorizationRequest: res.data,
        })
      );
      dispatch(dispatchUser());
      toast.success("vous avez annulé votre demande avec succés");
      dispatch(setIsLoadingState({ isLoading: false }));
    })
    .catch(() => {
      errorMsg();
      dispatch(setIsLoadingState({ isLoading: false }));
    });
};

export const respondToAuthorizationRequests = (obj, roles) => (dispatch) => {
  dispatch(setIsLoadingState({ isLoading: true }));
  axios
    .put(`${BASE_URL}/api/ask_for_authorisations/response`, obj)
    .then((res) => {
      toast.success("vous avez répondu a la demande avec succés");
      dispatch(
        treatAuthorizationItemInLists({
          treatedReceivedAuthorizationRequests: res.data,
          roles: roles,
        })
      );
      dispatch(setIsLoadingState({ isLoading: false }));
    })
    .catch(() => {
      errorMsg();
      dispatch(setIsLoadingState({ isLoading: false }));
    });
};

export const selectDuration = (state) => {
  return state.authorization.duration;
};

export const selectMyTreatedAuthorizationList = (state) => {
  return state.authorization.myTreatedAuthorizationList;
};

export const selectMyAuthorizationList = (state) => {
  return state.authorization.myAuthorizationList;
};

export const selectIsErrorState = (state) => {
  return state.authorization.isError;
};

export const selectIsLoadingState = (state) => {
  return state.authorization.isLoading;
};

export const selectNotTreatedReceivedAuthorizationRequests = (state) => {
  return state.authorization.notTreatedReceivedAuthorizationRequests;
};

export const selectTreatedReceivedAuthorizationRequests = (state) => {
  return state.authorization.treatedReceivedAuthorizationRequests;
};

export const {
  populateLeave,
  setLeaveList,
  populateMyLeaveList,
  populateMyAuthorizationList,
  setIsLoadingState,
  setIsErrorState,
  treatAuthorizationItemInLists,
  setMyTreatedAuthorizationList,
  setNotTreatedReceivedAuthorizationRequests,
  setTreatedReceivedAuthorizationRequests,
  setDuration,
  setIsSuccessState,
  updateAuthorizationItemInMyList,
} = AuthorizationSlice.actions;

export default AuthorizationSlice.reducer;
