import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/utils/apiRessources";

const initialState = {
    id: null,
    firstName: "",
    lastName: "",
    userEmail: "",
    address: null,
    birthdayDate: null,
    cellPhone: null,
    contractType: null,
    hiringDate: null,
    authorizationBalance: null,
    leaveBalance: null,
    totalOfLeaveBalance: null,
    totalOfAuthorizationBalance: null,
    salary: null,
    image: {},
    selectedUser: {},
    userPositions: [],
    teammates: [],
    roles: [],
    askForLeaves: [],
    redirectToFirstLogin: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
};

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setActiveUser: (state, action) => {
            state.id = action.payload.id;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.userEmail = action.payload.userEmail;
            state.address = action.payload.address;
            state.birthdayDate = action.payload.birthdayDate;
            state.cellPhone = action.payload.cellPhone;
            state.contractType = action.payload.contractType;
            state.hiringDate = action.payload.hiringDate;
            state.authorizationBalance = action.payload.authorizationBalance;
            state.leaveBalance = action.payload.leaveBalance;
            state.totalOfLeaveBalance = action.payload.totalOfLeaveBalance;
            state.totalOfAuthorizationBalance =
                action.payload.totalOfAuthorizationBalance;
            state.salary = action.payload.salary;
            state.userPositions = action.payload.userPositions;
            state.roles = action.payload.roles;
            state.image = action.payload.image;
            state.askForLeaves = action.payload.askForLeaves; 
        },
        populateTeammates: (state, action) => {
            state.teammates = action.payload.teammates;
        },
        unselectTeamamtes: (state, _action) => {
            state.teammates = [];
        },
        updateTeamMatesList: (state, action) => {
            const { teammates } = action.payload;

            const updatedItemIndex = state.teammates.findIndex(
                (leaveItem) => leaveItem.id === teammates.id
            );
            if (updatedItemIndex !== -1) {
                state.teammates[updatedItemIndex] = teammates;
            }
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload.selectedUser;
        },
        setUserImage: (state, action) => {
            state.image = action.payload.image;
        },
        setUserLogoutState: () => initialState,
        setIsLoadingState: (state, action) => {
            state.isLoading = action.payload.isLoading;
        },
        setRedirectToFirstLoginState: (state, action) => {
            state.redirectToFirstLogin = action.payload.redirectToFirstLogin;
        },
        setIsErrorState: (state, action) => {
            state.isError = action.payload.isError;
        },
        setIsSuccessState: (state, action) => {
            state.isSuccess = action.payload.isSuccess;
        },
    },
});

export const dispatchLogin = (obj) => (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
        .post(`${BASE_URL}/authentication_token`, obj)
        .then((res) => {
            if (res.data.token) {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("refreshToken", res.data.refresh_token);
                localStorage.setItem("exp", res.data.data.exp);
                dispatch(dispatch(setIsLoadingState({ isLoading: false })));
                axios.defaults.headers["Authorization"] = "Bearer " + res.data.token;
            }
        })
        .catch((err) => {
            if (err.response.status === 401) {
                dispatch(setIsErrorState({ isError: true }));
                setTimeout(() => {
                    dispatch(setIsErrorState({ isError: false }));
                    dispatch(dispatch(setIsLoadingState({ isLoading: false })));
                }, 500);
            }
        });
};

export const dispatchLogout = () => (dispatch) => {
    dispatch(setUserLogoutState());
    localStorage.clear();
    axios.defaults.headers["Authorization"] = null;
};

export const dispatchUser = () => (dispatch) => {
    dispatch(setRedirectToFirstLoginState({ redirectToFirstLogin: null }));
    axios
        .get(`${BASE_URL}/api/me`)
        .then((res) => {
            dispatch(
                setActiveUser({
                    id: res.data.id,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    userEmail: res.data.email,
                    address: res.data.address,
                    birthdayDate: res.data.birthdayDate.split("T")[0],
                    cellPhone: res.data.cellPhone,
                    contractType: res.data.contractType,
                    hiringDate: res.data.hiringDate,
                    authorizationBalance: res.data.authorizationBalance,
                    leaveBalance: res.data.leaveBalance,
                    totalOfLeaveBalance: res.data.totalOfLeaveBalance,
                    totalOfAuthorizationBalance: res.data.totalOfAuthorizationBalance,
                    salary: res.data.salary,
                    userPositions: res.data.positions,
                    roles: res.data.roles,
                    image: res.data.image,
                    askForLeaves: res.data.askForLeaves,
                })
            );
            dispatch(
                setRedirectToFirstLoginState({
                    redirectToFirstLogin: res.data?.positions.length === 0,
                })
            );
        })
        .catch((_err) => {
            localStorage.clear();
            axios.defaults.headers["Authorization"] = null;
        });
};

export const dispatchResetPassword = (obj) => (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
        .post(`${BASE_URL}/api/reset_password`, obj)
        .then(() => {
            dispatch(dispatch(setIsLoadingState({ isLoading: false })));
            dispatch(dispatch(setIsSuccessState({ isSuccess: true })));
            setTimeout(() => {
                dispatch(dispatch(setIsSuccessState({ isSuccess: false })));
            }, 500);
        })
        .catch((err) => {
            if (err.response.status === 404) {
                dispatch(setIsErrorState({ isError: true }));
                dispatch(dispatch(setIsSuccessState({ isSuccess: false })));
                setTimeout(() => {
                    dispatch(setIsErrorState({ isError: false }));
                    dispatch(dispatch(setIsLoadingState({ isLoading: false })));
                }, 500);
            }
        });
};

export const dispatchChangePassword = (obj) => (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
        .post(`${BASE_URL}/api/change_password?token=${obj.token}`, {
            password: obj.password,
        })
        .then(() => {
            dispatch(dispatch(setIsSuccessState({ isSuccess: true })));
            dispatch(dispatch(setIsLoadingState({ isLoading: false })));
            setTimeout(() => {
                dispatch(dispatch(setIsSuccessState({ isSuccess: false })));
            }, 500);
        })
        .catch((err) => {
            if (err.response.status === 404 || err.response.status === 401) {
                dispatch(dispatch(setIsSuccessState({ isSuccess: false })));
                dispatch(setIsErrorState({ isError: true }));
                setTimeout(() => {
                    dispatch(dispatch(setIsLoadingState({ isLoading: false })));
                    dispatch(setIsErrorState({ isError: false }));
                }, 500);
            }
        });
};

export const updateUserPassword = (userId, data) => (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
        .put(`${BASE_URL}/api/users/${userId}`, data)
        .then(() => {
            toast.success("Votre mot de passe a été modifié avec succès");
            dispatch(dispatch(setIsLoadingState({ isLoading: false })));
        })
        .catch((err) => {
            toast.error(`${err.response.data["hydra:description"]}`);
            dispatch(dispatch(setIsLoadingState({ isLoading: false })));
        });
};

export const updateUser = (userId, data) => (dispatch) => {
    dispatch(setIsSuccessState({ isSuccess: false }));
    axios
        .put(`${BASE_URL}/api/users/${userId}`, data)
        .then((res) => {
            dispatch(
                setActiveUser({
                    ...res.data,
                    userEmail: res.data.email,
                    birthdayDate: res.data.birthdayDate.split("T")[0],
                    userPositions: res.data.positions,
                })
            );
            dispatch(setIsSuccessState({ isSuccess: true }));
            toast.success("Vos informations ont été enregistrées avec succès");
        })
        .catch((_err) => {
            dispatch(setIsSuccessState({ isSuccess: false }));
            toast.error(
                "Une erreur s'est produite lors de la mofification de votre profil"
            );
        });
};

export const updateUserById = (id, data) => async (dispatch) => {
    try {
      const response = await axios.put(`${BASE_URL}/api/users/${id}`, data);
      const updatedUser = response.data;
  
      dispatch(updateTeamMatesList({ teammates: updatedUser }));
      toast.success("Les modification ont été enregistrées avec succès");
    } catch (error) {
      toast.error("Une erreur s'est produite lors de la modification d'un profil");
    }
  };

export const uploadUserImg = (userId, data) => (dispatch) => {
    axios
        .post(
            `${BASE_URL}/api/media_objects/upload_user_image?userId=${userId}`,
            data,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        .then((res) => {
            dispatch(setUserImage({ image: res.data }));
        })
        .catch((_err) => {
            toast.error(
                "Une erreur s'est produite lors de chargement de votre image"
            );
        });
};

export const getUserById = (id) => (dispatch) => {
    axios
        .get(`${BASE_URL}/api/users/${id}`)
        .then((res) => {
            dispatch(setSelectedUser({ selectedUser: res.data }));
        })
        .catch((_err) => {
            toast.error(
                "Une erreur s'est produite lors de recupération d'un utilisateur"
            );
        });
};

export const fetchTeammates =
    (teamIds = []) =>
        (dispatch) => {
            let queryParams = teamIds.reduce(
                (queryParamStr, teamId, currentIndex) => {
                    queryParamStr += currentIndex === 0 ? "?" : "&";
                    queryParamStr += `positions.team.id[]=${teamId}`;
                    return queryParamStr;
                },
                ""
            );

            queryParams += (teamIds.length === 0 ? "?" : "&") + "isActive=true";

            axios
                .get(`${BASE_URL}/api/users${queryParams}`)
                .then((res) => {
                    dispatch(populateTeammates({ teammates: res.data["hydra:member"] }));
                })
                .catch((_err) => {
                    toast.error(
                        "Une erreur s'est produite lors de la récupération des collègues"
                    );
                });
        };


export const fetchUsersByAdress =
    (active, address = "Tunis") =>
        (dispatch) => {
            axios
                .get(`${BASE_URL}/api/users?address=${address}&isActive=${active}`)
                .then((res) => {
                    dispatch(populateTeammates({ teammates: res.data["hydra:member"] }));
                })
                .catch((_err) => {
                    toast.error(
                        "Une erreur s'est produite lors de la récupération des collègues"
                    );
                });
        };

export const fetchAllExistingUsers =
    (active) =>
        (dispatch) => {
            axios
                .get(`${BASE_URL}/api/users?exists[positions]=true&isActive=${active}`)
                .then((res) => {
                    dispatch(populateTeammates({ teammates: res.data["hydra:member"] }));
                })
                .catch((_err) => {
                    toast.error(
                        "Une erreur s'est produite lors de la récupération des utilisateurs"
                    );
                });
        };

export const selectUserId = (state) => {
    return state.user.id;
};

export const selectUserFirstName = (state) => {
    return state.user.firstName;
};

export const selectUserLastName = (state) => {
    return state.user.lastName;
};

export const selectUserEmail = (state) => {
    return state.user.userEmail;
};

export const selectLeaveBalance = (state) => {
    return state.user.leaveBalance;
};

export const selectTotalOfLeaveBalance = (state) => {
    return state.user.totalOfLeaveBalance;
};

export const selectUserPositions = (state) => {
    return state.user.userPositions;
};

export const selectAuthorizationBalance = (state) => {
    return state.user.authorizationBalance;
};

export const selectIsLoadingState = (state) => {
    return state.user.isLoading;
};

export const selectIsErrorState = (state) => {
    return state.user.isError;
};

export const selectIsSuccessState = (state) => {
    return state.user.isSuccess;
};

export const selectRoles = (state) => {
    return state.user.roles;
};

export const selectRedirectToFirstLogin = (state) => {
    return state.user.redirectToFirstLogin;
};

export const selectTeammates = (state) => {
    return state.user.teammates;
};

export const selectUserImg = (state) => {
    return state.user.image;
};

export const selectSelectedUser = (state) => {
    return state.user.selectedUser;
};

export const selectAskForLeaves = (state) => {
  return state.user.askForLeaves;
};

export const {
    setActiveUser,
    populateTeammates,
    unselectTeamamtes,
    setUserLogoutState,
    setIsLoadingState,
    setRedirectToFirstLoginState,
    setIsErrorState,
    setIsSuccessState,
    updateTeamMatesList,
    setSelectedUser,
    setUserImage,
} = UserSlice.actions;

export default UserSlice.reducer;
