import React, { useState } from "react";
import _ from "lodash";

const Paginator = ({ totalItems, paginate }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const numberOfPages = Math.ceil(totalItems / 10);

  const clickPagination = (page) => {
    paginate(page);
    setCurrentPage(page);
  };

  return totalItems > 10 ? (
    <nav aria-label="Page navigation example">
      <ul className="pagination pagination-sm">
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Previous"
            disabled={currentPage === 1}
            onClick={() => {
              clickPagination(currentPage - 1);
            }}
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {_.times(numberOfPages, (i) => (
          <li className="page-item" key={i}>
            <button
              className="page-link"
              onClick={() => {
                clickPagination(i + 1);
              }}
            >
              {i + 1}
            </button>
          </li>
        ))}
        <li className="page-item">
          <button
            className="page-link"
            aria-label="Next"
            disabled={numberOfPages === currentPage}
            onClick={() => {
              clickPagination(currentPage + 1);
            }}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  ) : null;
};

export default Paginator;
