import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTreatedLeaveRequests,
  fetchNotTreatedLeaveRequests,
  fetchNotTreatedReceivedLeaveRequests,
  selectNotTreatedReceivedLeaveRequests,
  selectTreatedReceivedLeaveRequests,
} from "../../redux/slice/LeaveSlice";
import { selectRoles, selectUserId } from "../../redux/slice/UserSlice";
import { fetchTreatedReceivedLeaveRequests } from "./../../redux/slice/LeaveSlice";

import "./leaveValidation.sass";
import NotTreadedReceivedLeaveRequestTable from "./NotTreadedReceivedLeaveRequestTable";
import TreatedReceivedLeaveRequestsTable from "./TreatedReceivedLeaveRequestsTable";

const LeaveValidation = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const roles = useSelector(selectRoles);
  const notTreatedReceivedLeaveRequests = useSelector(
    selectNotTreatedReceivedLeaveRequests
  );
  const treatedReceivedLeaveRequests = useSelector(
    selectTreatedReceivedLeaveRequests
  );

  useEffect(() => {
    if (userId && (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH"))) {
      dispatch(fetchNotTreatedLeaveRequests());
      dispatch(fetchAllTreatedLeaveRequests());
    } else if (userId) {
      dispatch(fetchNotTreatedReceivedLeaveRequests(userId));
      dispatch(fetchTreatedReceivedLeaveRequests(userId));
    }
  }, [userId, dispatch, roles]);

  return (
    <div className="LeaveValidation-wrapper">
      <label>Demandes en cours</label>
      <NotTreadedReceivedLeaveRequestTable
        notTreatedReceivedLeaveRequests={notTreatedReceivedLeaveRequests}
        roles={roles}
      />

      <label>Demandes traitées</label>

      <TreatedReceivedLeaveRequestsTable
        treatedReceivedLeaveRequests={treatedReceivedLeaveRequests}
        roles={roles}
      />
    </div>
  );
};

export default LeaveValidation;
