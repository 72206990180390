import React from "react";
import DocRequestForm from "../../components/DocRequestForm/DocRequestForm";
import "./askForDoc.sass";
import "../../styles/globalStyle.sass";

export const AskForDoc = () => {
  return (
    <div className="wrapper-docRequest">
      <div className="title h1-Montserrat">Effectuer une demande</div>
      <div className="content-doc">
        <div className="white-space"></div>
        <div className="docRequestForm">
          <DocRequestForm />
        </div>
      </div>
    </div>
  );
};
