import React from "react";

import "./configurationsPage.sass";
import ConfigurationsRoute from "./../../routes/ConfigurationsRoute";
import { NavLink } from "react-router-dom";

const ConfigurationsPage = () => {
  return (
    <div className="configurationPage-wrapper">
      <div className="configurationPage-header">
        <div className="title">Configurations</div>
        <div
          className="btn-group rounded-pill switch"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio1"
            autoComplete="off"
            readOnly
          />
          <NavLink
            className="btn btn-outline-dark rounded-pill border border-0"
            to="/configurations/type-of-leave"
          >
            <label>Congé</label>
          </NavLink>
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio2"
            autoComplete="off"
            readOnly
          />
          <NavLink
            to="/configurations/holidays"
            className="btn btn-outline-dark rounded-pill border border-0"
          >
            <label>Vacances</label>
          </NavLink>
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio2"
            autoComplete="off"
            readOnly
          />
          <NavLink
            to="/configurations/type-of-docs"
            className="btn btn-outline-dark rounded-pill border border-0"
          >
            <label>Documents</label>
          </NavLink>
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio2"
            autoComplete="off"
            readOnly
          />
          <NavLink
            to="/configurations/team-and-position"
            className="btn btn-outline-dark rounded-pill border border-0"
          >
            <label>Team & Pos</label>
          </NavLink>
        </div>
      </div>
      <div className="configurationPage-routes">
        <ConfigurationsRoute />
      </div>
    </div>
  );
};

export default ConfigurationsPage;
