import React from "react";
import { useDispatch } from "react-redux";
import {
    BASE_URL,
    HYDRA_MEMBER,
    HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import { filterMediaObjects } from "../../services/utils/filterMediaObject";
import {
    fetchTreatedDocRequests,
} from "../../redux/slice/docSlice";
import Paginator from "../paginator/Paginator";

const TreatedDocRequests = ({
    treated,
    roles,
}) => {
    const dispatch = useDispatch();

    const clickPagination = (page) => {
        if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) {
            dispatch(fetchTreatedDocRequests(page));
        }
    };

    return (
        <>
            {treated[HYDRA_MEMBER]?.length > 0 ? (
                <div className="table-wrapper">
                    <table className="table  table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">De</th>
                                <th scope="col">Type</th>
                                <th scope="col">Etat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {treated[HYDRA_MEMBER]?.map((treatedObj, key) => (
                                <tr
                                    key={key}
                                    data-bs-toggle="modal"
                                    data-bs-target={`#staticBackdrop${key}2`}
                                    className="treatedHover"
                                >
                                    <td className="name_column">
                                        <div className="cell_name">{treatedObj.user.firstName}&nbsp;{treatedObj.user.lastName}</div>
                                    </td>
                                    <td>{treatedObj.typeOfDoc.type}</td>
                                    <td className="response_wrapper">
                                        {treatedObj.docStatus === "ACCEPTED" && (
                                            <div className="accepted">Traitée</div>
                                        )}
                                        {treatedObj.docStatus === "REJECTED" && (
                                            <div className="rejected">Refusée</div>
                                        )}
                                        {treatedObj.docStatus === "CANCELED" && (
                                            <div className="rejected">Annulée</div>
                                        )}
                                    </td>
                                    <td
                                        className="modal fade"
                                        id={`staticBackdrop${key}2`}
                                        data-bs-backdrop="static"
                                        data-bs-keyboard="false"
                                        tabIndex="-1"
                                        aria-labelledby="staticBackdropLabel"
                                        aria-hidden="true"
                                    >
                                        <div className={treatedObj.askForDocMediaObjects.length === 0? "modal-dialog modal-dialog-centered" : "modal-dialog modal-lg"}>
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="staticBackdropLabel">
                                                        Infos de la demande
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="modalContent">
                                                        <div>
                                                            De&nbsp;
                                                            <span>{treatedObj.user.firstName} {treatedObj.user.lastName}</span>
                                                        </div>
                                                        <div>
                                                            Demande de&nbsp;
                                                            <span>{treatedObj.typeOfDoc.type}</span>
                                                        </div>
                                                        <div>
                                                            Le&nbsp;
                                                            <span>
                                                                {dateHelpers.formatDateToFrenchFormat(
                                                                    treatedObj.dateSent.split("T")[0]
                                                                )}
                                                            </span>
                                                        </div>
                                                        {treatedObj.comment && (
                                                            <div>
                                                                Motif: <span>{treatedObj.comment}</span>
                                                            </div>
                                                        )}
                                                        {filterMediaObjects(treatedObj, "JUSTIFICATION", BASE_URL)}
                                                        {filterMediaObjects(treatedObj, "RESPONSE_FILE", BASE_URL)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Paginator
                        totalItems={treated[HYDRA_TOTALITEMS]}
                        paginate={clickPagination}
                    />
                </div>
            ) : (
                <div className="alert alert-info" role="alert">
                    Vous n'avez pas encore des demandes traitées
                </div>
            )}
        </>
    );
};

export default TreatedDocRequests;
