import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../services/utils/apiRessources";
import { toast } from "react-toastify";

const initialState = {
  id: null,
  type: null,
  typeOfDocList: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const typeOfDocSlice = createSlice({
  name: "typeOfDoc",
  initialState,
  reducers: {
    populateTypeOfDoc: (state, action) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
    },
    populateTypeOfDocList: (state, action) => {
      state.typeOfDocList = action.payload.typeOfDocList;
    },
    unselectTypeOfDoc: (state, _action) => {
      state.id = null;
      state.type = null;
    },
    unselectTypeOfDocList: (state, _action) => {
      state.typeOfDocList = null;
    },
    addItemToTypeOfDocList: (state, action) => {
      state.typeOfDocList.push(action.payload);
    },
    removeItemFromTypeOfDocList: (state, action) => {
      const index = state.typeOfDocList.findIndex(
        (item) => item.id === action.payload
      );

      if (index !== -1) {
        state.typeOfDocList.splice(index, 1);
      }
    },
    updateElementIntypeOfDocList: (state, action) => {
      const index = state.typeOfDocList.findIndex(
        (item) => item.id === action.payload.id
      );
      if(index !== -1){
        state.typeOfDocList[index] = action.payload;
      }
    },
    setIsLoadingState: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsErrorState: (state, action) => {
      state.isError = action.payload.isError;
    },
    setIsSuccessState: (state, action) => {
      state.isSuccess = action.payload.isSuccess;
    },
  },
});

export const  fetchTypesOfDoc = ( isEnable = false ) => (dispatch) => {
  const param = isEnable ? "?isEnable=1" : "";
  axios
    .get(`${BASE_URL}/api/type_of_docs${param}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((res) => {
      dispatch(
        populateTypeOfDocList({ typeOfDocList: res.data })
      );
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de la récupération des types de documents");
    });
};

export const changeIsEnableState = (data,id) => (dispatch) => {
  axios
    .put(`${BASE_URL}/api/type_of_docs/${id}`,data)
    .then((res) => {
      toast.success("Vous avez changé le type de document avec succéss");
      dispatch(updateElementIntypeOfDocList(res.data));
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de du changement d'un type de document");
    });
};

export const deleteTypeOfDoc = (id) => (dispatch) => {
  axios
    .delete(`${BASE_URL}/api/type_of_docs/${id}`)
    .then((_res) => {
      toast.success("Vous avez supprimé un type de document avec succéss");
      dispatch(removeItemFromTypeOfDocList(id));
    })
    .catch((_err) => {
      toast.error(
        "Une erreur s'est produite lors de la suppression d'un type de document"
      );
    });
};

export const addTypeOfDoc = (data) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/type_of_docs`, data)
    .then((res) => {
      toast.success("Vous avez ajouté un type de document avec succéss");
      dispatch(addItemToTypeOfDocList(res.data));
    })
    .catch((_err) => {
      toast.error(
        "Une erreur s'est produite lors de l'ajout d'un type de document"
      );
    });
};

export const selectTypesOfDoc = (state) => {
  return state.typeOfDoc.typeOfDocList;
};

export const selectIsLoading = (state) => {
  return state.typeOfDoc.isLoading;
};

export const {
  populateTypeOfDoc,
  populateTypeOfDocList,
  unselectTypeOfDoc,
  unselectTypeOfDocList,
  addItemToTypeOfDocList,
  removeItemFromTypeOfDocList,
  updateElementIntypeOfDocList,
  setIsLoadingState,
  setIsErrorState,
  setIsSuccessState,
} = typeOfDocSlice.actions;

export default typeOfDocSlice.reducer;
