import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectUserId } from "../../redux/slice/UserSlice";
import {
  fetchMyRequests,
  fetchMyTreatedleaveRequests,
  selectIsLoadingState,
  selectMyleavesList,
  selectMyTreatedLeaveList,
} from "./../../redux/slice/LeaveSlice";
import MyLeavesListTable from "./MyLeavesListTable";

import "./myLeaves.sass";
import MyTreatedLeaveListTable from "./MyTreatedLeaveListTable";

const MyLeaves = () => {
  const dispatch = useDispatch();
  const myLeavesList = useSelector(selectMyleavesList);
  const myTreatedLeavesList = useSelector(selectMyTreatedLeaveList);
  const isLoading = useSelector(selectIsLoadingState);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchMyRequests({
          "user.id": userId,
          page: 1,
          finalStatus: "IN_PROGRESS",
        })
      );
      dispatch(fetchMyTreatedleaveRequests(userId));
    }
  }, [dispatch, userId]);

  return (
    <div className="myLeaves-wrapper">
      <label>Demandes en cours</label>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      ) : (
        <MyLeavesListTable myLeavesList={myLeavesList} />
      )}

      <label>Demandes traitées</label>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      ) : (
        <MyTreatedLeaveListTable myTreatedLeavesList={myTreatedLeavesList} />
      )}
    </div>
  );
};

export default MyLeaves;
