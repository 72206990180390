import React from "react";
import { dateHelpers } from "../../services/utils/dateHelpers";

const ModalContent = ({ obj, respondToLeaveRequest, notTreated = true }) => {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Demande à vérifier
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="modal-body">
        <div className="modalContent">
          <div>
            De&nbsp;
            <span>
              {obj.user.firstName}&nbsp;{obj.user.lastName}
            </span>
          </div>
          <div>
            &nbsp; Demande de&nbsp;
            <span>{obj.duration}</span> h
          </div>
          <div>
            &nbsp; Date : &nbsp;
            <span>
              {dateHelpers.formatDateToFrenchFormat(
                obj.startDate.split("T")[0]
              )}
            </span>
            de&nbsp;
            <span>{dateHelpers.getTimeFromDate(obj.startDate, true)}</span>
            &nbsp;au&nbsp;
            <span>{dateHelpers.getTimeFromDate(obj.endDate, true)}</span>
          </div>
          {obj.commentary !== "" && (
            <div>
              Motif: <span>{obj.commentary}</span>
            </div>
          )}
        </div>
      </div>
      {notTreated && (
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              respondToLeaveRequest({
                authorizationId: obj.id,
                response: "rejected",
              });
            }}
          >
            Refuser
          </button>
          <button
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              respondToLeaveRequest({
                authorizationId: obj.id,
                response: "accepted",
              });
            }}
          >
            Accepter
          </button>
        </div>
      )}
    </div>
  );
};

export default ModalContent;
