import React from "react";

const ModalFooter = ({ isErrorState, confirmRequest }) => {
  return (
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Annuler
      </button>
      {!isErrorState && (
        <button
          type="button"
          className="btn btn-primary"
          data-bs-dismiss="modal"
          onClick={() => confirmRequest()}
        >
          Confirmer
        </button>
      )}
    </div>
  );
};

export default ModalFooter;
