import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ImageConfig } from '../../services/ImageConfig';


import './dropFileInput.sass';
import "../DocRequestForm/docRequestForm.sass";


const DropFileInput = props => {

    const wrapperRef = useRef(null);

    const [fileList, setFileList] = useState([]);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList);
            props.onFileChange(updatedList);
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    }

    return (
        <>
            <div
                ref={wrapperRef}
                className="dropFileInput"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="dropFileInput_label">
                    <div className='labelDoc'>Sélectionnez ou glissez-déposez vos fichiers dans le formulaire</div>
                    <div className='subLabelUpload'>Il est possible de charger des fichiers .pdf, doc, docx, .png, jpg ou .jpeg d'une taille maximale de 10 Mo</div>
                </div>
                <input type="file" value="" onChange={onFileDrop} />
            </div>
            {
                fileList.length > 0 ? (
                    <div className="dropFilePreview mt-4">
                        <p className="dropFilePreview_title">
                            fichiers prêts à être chargés
                        </p>
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="dropFilePreview_item">
                                    <img className='mr-2' style={{ minWidth: "4rem", maxWidth: "5rem" }} src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                                    <div className="dropFilePreview_item_info">
                                        <p>{item.name}</p>
                                        <p>{item.size}B</p>
                                    </div>
                                    <span className="dropFilePreview_item_del" onClick={() => fileRemove(item)}>x</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
        </>
    );
}

DropFileInput.propTypes = {
    onFileChange: PropTypes.func
}

export default DropFileInput;
