import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, HYDRA_MEMBER } from "../../services/utils/apiRessources";
import { toast } from "react-toastify";

const initialState = {
  id: null,
  day: null,
  startDate: null,
  endDate: null,
  isPaid: false,
  holidaysList: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const HolidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    populateHoliday: (state, action) => {
      state.id = action.payload.id;
      state.day = action.payload.day;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.isPaid = action.payload.isPaid;
    },
    populateHolidaysList: (state, action) => {
      state.holidaysList = action.payload.holidaysList;
    },
    updateElementinHolidaysList: (state, action) => {
      const index = state.holidaysList[HYDRA_MEMBER].findIndex(
        (item) => item.id === action.payload.id
      );

      if (index !== -1) {
        state.holidaysList[HYDRA_MEMBER][index] = action.payload;
      }
    },
    unselectHoliday: (state, _action) => {
      state.id = null;
      state.day = null;
      state.startDate = null;
      state.endDate = null;
      state.isPaid = null;
    },
    unselectHolidaysList: (state, _action) => {
      state.holidaysList = null;
    },
    setIsLoadingState: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsErrorState: (state, action) => {
      state.isError = action.payload.isError;
    },
    setIsSuccessState: (state, action) => {
      state.isSuccess = action.payload.isSuccess;
    },
  },
});

export const loadDaysOff = () => (dispatch) => {
  dispatch(setIsLoadingState({ isLoading: true }));
  axios
    .get(`${BASE_URL}/api/getDaysOff`)
    .then(() => {
      toast.success("Vous avez actualiser la liste des vacances avec succés");
      dispatch(setIsLoadingState({ isLoading: false }));
    })
    .catch(() => {
      toast.error(
        "Une erreur s'est produite lors de l'actualisation la liste des vacances"
      );
    });
};

export const getDaysOff =
  (page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(`${BASE_URL}/api/holidays?order[isPaid]=desc&page=${page}`)
      .then((res) => {
        dispatch(populateHolidaysList({ holidaysList: res.data }));
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        toast.error(
          "Une erreur s'est produite lors de la recharge de la liste des vacances"
        );
      });
  };

export const updateHolidayItem = (id, data) => (dispatch) => {
  axios
    .put(`${BASE_URL}/api/holidays/${id}`, data)
    .then((res) => {
      toast.success("Vous avez changé l'état du champ payé d'un jours férié");
      dispatch(updateElementinHolidaysList(res.data));
    })
    .catch(() => {
      toast.error(
        "Une erreur s'est produite lors changement de l'état du champ payé d'un jours férié"
      );
    });
};


export const getHolidaysInWeek = (startDate,endDate) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/holidays?startDate[after]=${startDate}&startDate[before]=${endDate}&isPaid=1`)
    .then((res) => {
      dispatch(populateHolidaysList({ holidaysList: res.data }));
    })
    .catch(() => {
      toast.error(
        "Une erreur s'est produite lors de la recharge de la liste des vacances dans semaine"
      );
    });
};

export const selectHoliday = (state) => {
  return {
    id: state.holiday.id,
    day: state.holiday.day,
    startDay: state.holiday.startDate,
    endDate: state.holiday.endDate,
    isPaid: state.holiday.isPaid,
  };
};

export const selectHolidayList = (state) => {
  return state.holiday.holidaysList;
};

export const selectIsLoadingState = (state) => {
  return state.holiday.isLoading;
};

export const selectIsErrorState = (state) => {
  return state.holiday.isError;
};

export const selectIsSuccessState = (state) => {
  return state.holiday.isSuccess;
};

export const {
  populateHoliday,
  populateHolidaysList,
  unselectHoliday,
  unselectHolidaysList,
  setIsLoadingState,
  setIsErrorState,
  setIsSuccessState,
  updateElementinHolidaysList,
} = HolidaySlice.actions;

export default HolidaySlice.reducer;
