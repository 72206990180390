import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  dispatchLogin,
  dispatchLogout,
  selectIsErrorState,
  selectIsLoadingState,
} from "../../redux/slice/UserSlice";
import CosaLogo from "../../assets/CosaLogo";

import "./loginPage.sass";
import { unselectLeave } from "../../redux/slice/LeaveSlice";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const isLoadingState = useSelector(selectIsLoadingState);
  const isErrorState = useSelector(selectIsErrorState);

  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    setCredentials({ ...credentials, [name]: value });
    setEmailError(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (credentials.email.endsWith("@cosavostra.com")) {
      setEmailError(false);
      dispatch(dispatchLogin(credentials));
    } else {
      setEmailError(true);
    }
  };

  useEffect(() => {
    dispatch(dispatchLogout());
    dispatch(unselectLeave());
  }, [dispatch]);

  useEffect(() => {
    if (token != null) {
      navigate("/");
    }
  }, [token, navigate]);

  useEffect(() => {
    setLoader(isLoadingState);
  }, [isLoadingState]);

  useEffect(() => {
    if (isErrorState) {
      toast.error("Vérifiez votre email ou votre mot de passe");
    }
  }, [isErrorState]);

  useEffect(() => {
    setLoader(false);
  }, []);

  return (
    <>
      <div className="login">
        <div className="logo_wrapper">
          <div className="login_logo">
            <CosaLogo />
          </div>

          <div className="logo_text">
            <h1>CosaRH</h1>
            <div>Made with ❤️ by CosaVostra</div>
          </div>
        </div>
        <div className="auth_form_card">
          <div className="card">
            <div className="card-body p-3">
              <div className="login_form_wrapper">
                <form onSubmit={handleFormSubmit}>
                  <h3>Se connecter</h3>
                  <div className="mb-3 email_wrapper">
                    <label className="form-label d-flex justify-content-start">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      aria-describedby="emailHelp"
                      onChange={handleChange}
                      value={credentials.email}
                      required
                    />
                    {emailError === true ? (
                      <div className="alert alert-danger mt-2" role="alert">
                        Vous devez avoir une adresse Email cosavostra
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mb-3 password_wrapper">
                    <label className="d-flex justify-content-start form-label">
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      onChange={handleChange}
                      value={credentials.password}
                      required
                    />
                  </div>

                  <div className="login_auth_wrapper">
                    <NavLink
                      to="/reset-password"
                      className="login_forget-password"
                    >
                      Mot de passe oublié ?
                    </NavLink>
                    <div className="button_or_circular_prog">
                    {loader ? (
                      <div
                        className="spinner-border text-primary circular_prog"
                        role="status"
                      ></div>
                    ) : (
                      <button className="login_button" onClick={handleFormSubmit}>
                        Se connecter
                      </button>
                    )}</div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
