import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotTreatedLeaveRequests,
  fetchNotTreatedReceivedLeaveRequests,
  resondToLeaveRequests,
} from "../../redux/slice/LeaveSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import {
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import Paginator from "../paginator/Paginator";
import UserInformationsPopup from "./../userInformationsPopup/UserInformationsPopup";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const NotTreadedReceivedLeaveRequestTable = ({
  notTreatedReceivedLeaveRequests,
  roles,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  const respondToLeaveRequest = (data) => {
    dispatch(resondToLeaveRequests(data, roles));
  };

  const clickPagination = (page) => {
    if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) {
      dispatch(fetchNotTreatedLeaveRequests(page));
    } else if (userId) {
      dispatch(fetchNotTreatedReceivedLeaveRequests(userId, page));
    }
  };

  return (
    <>
      {notTreatedReceivedLeaveRequests[HYDRA_MEMBER]?.length > 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th
                  scope="col"
                  className={roles.includes("ROLE_ADMIN") ? "th" : "th-treated"}
                >
                  De
                </th>
                <th scope="col">Type</th>
                {roles.includes("ROLE_ADMIN") && <th scope="col">Traitée</th>}
                <th scope="col">Etat</th>
              </tr>
            </thead>
            <tbody>
              {notTreatedReceivedLeaveRequests[HYDRA_MEMBER]?.map(
                (obj, key) => (
                  <tr key={key}>
                    <td className="name_column">
                      <div className="name_column_container">
                        {obj.user.firstName} {obj.user.lastName}
                        <UserInformationsPopup user={obj.user} />
                      </div>
                    </td>
                    <td className="wa">{obj.typeOfLeave.type}</td>
                    {roles.includes("ROLE_ADMIN") && (
                      <td>
                        {obj.treated ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <HourglassBottomIcon />
                        )}
                      </td>
                    )}
                    {obj.treated &&
                      !roles.includes("ROLE_ADMIN") &&
                      !roles.includes("ROLE_RH") ? (
                      <td className="response_wrapper">
                        <div className="inProgress">En cours</div>
                      </td>
                    ) : (
                      <td>
                        <div
                          className="verification_btn"
                          data-bs-toggle="modal"
                          data-bs-target={`#staticBackdrop${key}1Leave`}
                        >
                          Vérifier &gt;
                        </div>
                      </td>
                    )}
                    <td
                      className="modal fade"
                      id={`staticBackdrop${key}1Leave`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Demande à vérifier
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="modalContent">
                              <div>
                                De&nbsp;
                                <span>{`${obj.user.firstName} ${obj.user.lastName}`}</span>
                              </div>
                              <div>
                                Demande de&nbsp;
                                <span>{obj.typeOfLeave.type}</span>&nbsp;
                                de&nbsp;
                                <span>{obj.notPaidDuration}</span> jours
                              </div>
                              <div>
                                Du&nbsp;
                                <span>
                                  {dateHelpers.formatDateToFrenchFormat(
                                    obj.startDate.split("T")[0]
                                  )}
                                </span>
                                &nbsp;
                                <span>
                                  {dateHelpers.isBeforeMidDay(
                                    obj.startDate.split("T")[1].split(":")[0]
                                  )
                                    ? "Matin"
                                    : "Aprés midi"}
                                </span>
                                &nbsp; au&nbsp;
                                <span>
                                  {dateHelpers.formatDateToFrenchFormat(
                                    obj.endDate.split("T")[0]
                                  )}
                                </span>
                                &nbsp;
                                <span>
                                  {dateHelpers.isBeforeMidDay(
                                    obj.endDate.split("T")[1].split(":")[0],
                                    true
                                  )
                                    ? "Matin"
                                    : "Journée entière"}
                                </span>
                              </div>
                              <div className="leave-informations">
                                <div>Solde de congés</div>
                                <span>{obj.user.leaveBalance}</span>
                              </div>
                              {obj.commentary && (
                                <div>
                                  Motif: <span>{obj.commentary}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          {(roles.includes("ROLE_ADMIN") || roles.includes("ROLE_MANAGER")) && (
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  const data = {
                                    leaveId: obj.id,
                                    responderId: userId,
                                    response: "rejected",
                                  };
                                  respondToLeaveRequest(data);
                                }}
                              >
                                Refuser
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  const data = {
                                    leaveId: obj.id,
                                    responderId: userId,
                                    response: "accepted",
                                  };
                                  respondToLeaveRequest(data);
                                }}
                              >
                                Accepter
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <Paginator
            totalItems={notTreatedReceivedLeaveRequests[HYDRA_TOTALITEMS]}
            paginate={clickPagination}
          />
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas des Demandes en cours
        </div>
      )}
    </>
  );
};

export default NotTreadedReceivedLeaveRequestTable;
