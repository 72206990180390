import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchDocRequests,
    fetchTreatedDocRequests,
    respondToDocRequests,
    sendDocRequestFiles,
} from "../../redux/slice/docSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import { uploadFile, selectFileIdsList, unselectFileIdsList, selectIsLoadingState } from "../../redux/slice/fileSlice";
import {
    BASE_URL,
    HYDRA_MEMBER,
    HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import { filterMediaObjects } from "../../services/utils/filterMediaObject";
import Paginator from "../paginator/Paginator";
import Divider from "@mui/material/Divider";
import DropFileInput from "../fileUploadBox/DorpFileInput";

const NotTreatedDocRequests = ({
    notTreated,
    roles,
}) => {

    const dispatch = useDispatch();
    const userId = useSelector(selectUserId);
    const responseFileIds = useSelector(selectFileIdsList);
    const fileUploadLoading = useSelector(selectIsLoadingState);
    const [responseFiles, setResponseFiles] = useState([]);

    const uploadFiles = (arr) => {
        const formData = new FormData();

        arr.forEach((file) => {
            formData.append("file", file);
            dispatch(
                uploadFile(formData)
            );
        });
        setResponseFiles([]);
    };

    const respond = (id, response) => {
        dispatch(respondToDocRequests(id, response));
    };

    const clickPagination = (page) => {
        if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) {
            dispatch(fetchDocRequests(page));
        } else if (userId) {
            dispatch(fetchTreatedDocRequests(userId, page));
        }
    };

    return (
        <>
            {notTreated[HYDRA_MEMBER]?.length > 0 ? (
                <div className="table-wrapper">
                    <table className="table  table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">De</th>
                                <th scope="col">Type</th>
                                <th scope="col">Etat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notTreated[HYDRA_MEMBER]?.map(
                                (notTreatedObj, key) => (
                                    <tr key={key}>
                                        <td className="name_column">
                                            {notTreatedObj.user.firstName} {notTreatedObj.user.lastName}
                                        </td>
                                        <td className="wa">{notTreatedObj.typeOfDoc.type}</td>
                                        {roles.includes("ROLE_ADMIN") ? (
                                            <td className="response_wrapper">
                                                <div className="inProgress">En cours</div>
                                            </td>
                                        ) : (
                                            <td>
                                                <div
                                                    className="verification_btn"
                                                    data-bs-toggle="modal"
                                                    data-bs-target={`#staticBackdrop${key}1Document`}
                                                >
                                                    Vérifier &gt;
                                                </div>
                                            </td>
                                        )}
                                        {roles.includes("ROLE_RH") && <td
                                            className="modal fade"
                                            id={`staticBackdrop${key}1Document`}
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex="-1"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4
                                                            className="modal-title"
                                                            id="staticBackdropLabel"
                                                        >
                                                            Demandes de documents à vérifier
                                                        </h4>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                            onClick={() => {
                                                                setResponseFiles([]);
                                                                dispatch(unselectFileIdsList());
                                                            }}
                                                        ></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="modalContent">
                                                            <div>
                                                                De&nbsp;
                                                                <span>{notTreatedObj.user.firstName} {notTreatedObj.user.lastName}</span>
                                                            </div>
                                                            <div>
                                                                Demande de&nbsp;
                                                                <span>{notTreatedObj.typeOfDoc.type}</span>
                                                            </div>
                                                            <div>
                                                                Le &nbsp;
                                                                <span>
                                                                    {dateHelpers.formatDateToFrenchFormat(
                                                                        notTreatedObj.dateSent.split("T")[0]
                                                                    )}
                                                                </span>
                                                            </div>
                                                            {notTreatedObj.comment !== "" && (
                                                                <div>
                                                                    Motif&nbsp;
                                                                    <span>{notTreatedObj.comment}</span>
                                                                </div>
                                                            )}
                                                            {filterMediaObjects(notTreatedObj, "JUSTIFICATION", BASE_URL)}
                                                            <div>
                                                                <Divider />
                                                            </div>
                                                            <div style={{
                                                                fontFamily: 'Montserrat',
                                                                fontStyle: "normal",
                                                                fontWeight: 500,
                                                                fontSize: 18,
                                                                lineHeight: '120%',
                                                                alignSelf: 'center'
                                                            }}>Lui Fournir</div>
                                                            <div className="uploadResponseDoc-wrapper">
                                                                <div className="uploadResponseDoc">
                                                                    <DropFileInput
                                                                        onFileChange={(fileObjs) => setResponseFiles(fileObjs)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {responseFiles.length !== 0 && responseFileIds.length === 0 && <button
                                                                className="btn btn-outline-secondary"
                                                                style={{
                                                                    borderRadius: "0.5rem",
                                                                    height: "3rem",
                                                                    margin: "0 1rem",
                                                                }}
                                                                onClick={() => uploadFiles(responseFiles)}
                                                            >   Uploader &nbsp;
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-upload-fill" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M8 0a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 4.095 0 5.555 0 7.318 0 9.366 1.708 11 3.781 11H7.5V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11h4.188C14.502 11 16 9.57 16 7.773c0-1.636-1.242-2.969-2.834-3.194C12.923 1.999 10.69 0 8 0zm-.5 14.5V11h1v3.5a.5.5 0 0 1-1 0z" />
                                                                </svg>
                                                            </button>}
                                                        </div>

                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => respond(notTreatedObj.id, "rejected")}
                                                        >
                                                            Refuser
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => {
                                                                if (!fileUploadLoading) {
                                                                    respond(notTreatedObj.id, "accepted");
                                                                    dispatch(sendDocRequestFiles("RESPONSE_FILE", notTreatedObj.id, responseFileIds));
                                                                    dispatch(unselectFileIdsList());
                                                                }
                                                            }
                                                            }
                                                        >
                                                            Accepter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>}
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    <Paginator
                        totalItems={notTreated[HYDRA_TOTALITEMS]}
                        paginate={clickPagination}
                    />
                </div>
            ) : (
                <div className="alert alert-info" role="alert">
                    Vous n'avez pas des Demandes en cours
                </div>
            )}
        </>
    );
};

export default NotTreatedDocRequests;
