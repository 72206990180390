import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectUserId } from "../../redux/slice/UserSlice";
import { dateHelpers } from "../../services/utils/dateHelpers";
import { filterMediaObjects } from "../../services/utils/filterMediaObject";
import {
  fetchMyDocRequests,
  fetchMyTreatedDocRequests,
  cancelDocRequest,
  selectMyDocsList,
  selectMyTreatedDocsList,
} from "../../redux/slice/docSlice";

import "../../styles/globalStyle.sass";
import "./myDocuments.sass";
import { BASE_URL } from "../../services/utils/apiRessources";

const MyDocsTable = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const myDocsList = useSelector(selectMyDocsList);
  const myTreatedDocsList = useSelector(selectMyTreatedDocsList);

  useEffect(() => {
    if (userId) {
      dispatch(fetchMyDocRequests(userId));
      dispatch(fetchMyTreatedDocRequests(userId));
    }
  }, [dispatch, userId]);

  return (
    <div className="myDocs-wrapper">
      <label>Demandes en cours</label>
      {myDocsList.length !== 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Date</th>
                <th scope="col">État</th>
              </tr>
            </thead>
            <tbody>
              {myDocsList?.map(
                (obj, key) =>
                  obj.docStatus === "IN_PROGRESS" && (
                    <tr
                      key={key}
                      data-bs-toggle="modal"
                      data-bs-target={`#staticBackdrop${key}4`}
                      className="toCancel"
                    >
                      <td className="type-wrapper">{obj.typeOfDoc.type}</td>
                      <td>
                        {`${dateHelpers.formatDateToFrenchFormat(
                          obj.dateSent.split("T")[0]
                        )}`}
                      </td>
                      <td className="response_wrapper">
                        <div className="inProgress">En cours</div>
                      </td>
                      <td
                        className="modal fade"
                        id={`staticBackdrop${key}4`}
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <h6>Vous étes sur de vouloir annuler votre demande de document ?</h6>
                            </div>
                            <div className="modal-footer" style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                              <button
                                type="button"
                                className="btn btn-outline-primary"
                                data-bs-dismiss="modal"
                              >
                                Non
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  dispatch(cancelDocRequest(obj.id))
                                }}
                              >
                                Confimer
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas des demandes en cours
        </div>
      )}
      <label>Demandes traitées</label>
      {myTreatedDocsList.length !== 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Date</th>
                <th scope="col">État</th>
              </tr>
            </thead>
            <tbody>
              {myTreatedDocsList?.map(
                (obj, key) =>
                  <tr
                    key={key}
                    data-bs-toggle="modal"
                    data-bs-target={`#staticBackdrop${key}3`}
                    className="myTreatedHover"
                  >
                    <td className="type-wrapper">{obj.typeOfDoc.type}</td>
                    <td>
                      {`${dateHelpers.formatDateToFrenchFormat(
                        obj.dateSent.split("T")[0]
                      )}`}
                    </td>
                    <td className="response_wrapper">
                      {obj.docStatus === "ACCEPTED" && (
                        <div className="accepted">Traitée</div>
                      )}
                      {obj.docStatus === "REJECTED" && (
                        <div className="rejected">Refusée</div>
                      )}
                      {obj.docStatus === "CANCELED" && (
                        <div className="rejected">Annulée</div>
                      )}
                    </td>
                    <td
                      className="modal fade"
                      id={`staticBackdrop${key}3`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className={obj.askForDocMediaObjects.length === 0? "modal-dialog modal-dialog-centered" : "modal-dialog modal-lg"}>
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">
                              Infos de la demande
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="modalContent">
                              <div>
                                Demande de&nbsp;
                                <span>{obj.typeOfDoc.type}</span>
                              </div>
                              <div>
                                Le&nbsp;
                                <span>
                                  {dateHelpers.formatDateToFrenchFormat(
                                    obj.dateSent.split("T")[0]
                                  )}
                                </span>
                              </div>
                              {obj.comment && (
                                <div>
                                  Motif: <span>{obj.comment}</span>
                                </div>
                              )}
                              {filterMediaObjects(obj, "JUSTIFICATION", BASE_URL)}
                              {filterMediaObjects(obj, "RESPONSE_FILE", BASE_URL)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas des demandes traitées
        </div>
      )}
    </div>
  );
};

export default MyDocsTable;
