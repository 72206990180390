import FullCalendar from "@fullcalendar/react";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayGridPlugin from "@fullcalendar/daygrid";
import timelinePlugin from '@fullcalendar/timeline';

import {
    fetchAllAcceptedleaveRequests,
    fetchMyRequests,
    selectIsLoadingState,
    selectMyleavesList,
} from "../../redux/slice/LeaveSlice";
import { selectleavesList } from "./../../redux/slice/LeaveSlice";
import {
    selectLeaveBalance,
    selectUserId,
    dispatchUser,
} from "../../redux/slice/UserSlice";
import { dateHelpers } from "../../services/utils/dateHelpers";
import MyLeavesList from "./MyLeavesList";
import { fetchNotSeenNotifications } from "../../redux/slice/NotificationSlice";

import "./dashboardPage.sass";
import {
    getHolidaysInWeek,
    selectHolidayList,
} from "../../redux/slice/HolidaySlice";
import { HYDRA_MEMBER } from "./../../services/utils/apiRessources";

const DashboardPage = () => {
    const dispatch = useDispatch();
    const leavesList = useSelector(selectleavesList);
    const leaveBalance = useSelector(selectLeaveBalance);
    const userId = useSelector(selectUserId);
    const myLeavesList = useSelector(selectMyleavesList);
    const isLoading = useSelector(selectIsLoadingState);
    const holidaysList = useSelector(selectHolidayList);
    const todayDate = new Date();

    useEffect(() => {
        if (userId === null) {
            dispatch(dispatchUser());
        }
        if (userId) {
            dispatch(
                fetchMyRequests({
                    "user.id": userId,
                    page: 1,
                })
            );
            dispatch(fetchNotSeenNotifications(userId));
        }
    }, [dispatch, userId]);

    const correctHour = (str) => {
        const reg = /T(\d+):/;
        const hour = str.match(reg)[1];
        if (hour === '22') {
            return str.replace(reg, 'T23:');
        } else if (hour === '11') {
            return str.replace(reg, 'T12:');
        } else {
            return str;
        }
    }


    const events = leavesList?.map((leave) => ({
        title: `${leave.user.firstName} ${leave.user.lastName} ${leave.notPaidDuration}j`,
        start: correctHour(leave.startDate),
        end: correctHour(leave.endDate),
        color: leave.user.id === userId ? "grey" : "blue",
    }));

    holidaysList[HYDRA_MEMBER]?.map((holiday) =>
        events.push({
            title: holiday.day,
            start: holiday.startDate,
            end: holiday.endDate,
            color: "red",
        })
    );

    return (
        <div className="dashboardPage-wrapper">
            <div className="dashboardPage-header">
                <div className="scores">
                    <div>Votre solde de congés</div>
                    <div className="score">
                        {leaveBalance}
                    </div>
                    <div className="lastDate">
                        Solde au {todayDate.toLocaleDateString()}
                    </div>
                </div>
                <div className="lastHistory">
                    <div>Historique de mes congés</div>
                    {isLoading ? (
                        <div className="loader-wrapper">
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                    ) : (
                        <MyLeavesList myLeavesList={myLeavesList} />
                    )}
                </div>
            </div>
            <div className="agenda">
                <FullCalendar
                    plugins={[timelinePlugin]}
                    initialView="timelineWeek"
                    schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                    events={events}
                    weekends={false}
                    slotMinWidth="10px"
                    locale="fr"
                    nowIndicator={true}
                    contentHeight="200px"
                    buttonText={{ today: "Aujourd'hui" }}
                    displayEventTime={false}
                    slotDuration='12:00:00'
                    slotLabelInterval="12:00:00"
                    slotLabelFormat={
                        [
                            { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
                            { hour: 'numeric' }
                        ]
                    }
                    slotLabelContent={arg => {
                        if (arg.level === 1) {
                            arg.text = arg.text === '00 h' ? 'Matin' : 'Aprés-midi';
                        }
                    }}
                    datesSet={(arg) => {
                        dispatch(
                            fetchAllAcceptedleaveRequests(
                                userId,
                                arg.startStr.split("T")[0],
                                arg.endStr.split("T")[0]
                            )
                        );
                        dispatch(
                            getHolidaysInWeek(
                                arg.startStr.split("T")[0],
                                arg.endStr.split("T")[0]
                            )
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default DashboardPage;
