import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import MyLeaves from './../components/myLeavs/MyLeaves';
import MyAuthorizations from './../components/myAuthorizations/MyAuthorizations';

const MyRequestsRoute = () => {
  return (
    <Routes>
      <Route
        path="my-leaves-requests"
        element={
          <PrivateRoute>
            <MyLeaves />
          </PrivateRoute>
        }
      />
      <Route
        path="my-authorization-request"
        element={
          <PrivateRoute>
            <MyAuthorizations />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default MyRequestsRoute;
