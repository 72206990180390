import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import { authService } from "../../services/authService";
import { dispatchUser, selectRedirectToFirstLogin } from "../../redux/slice/UserSlice";

const PrivateRoute = ({ children }) => {
  authService.setup();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectToFirstLogin = useSelector(selectRedirectToFirstLogin);
  const token = localStorage.getItem("token");

  React.useEffect(() => {
    if (token) {
      dispatch(dispatchUser());
    }
  }, [token, dispatch]);

  React.useEffect(() => {
    if (token && redirectToFirstLogin) {
      navigate("/first-login");
    }
  }, [token, navigate, redirectToFirstLogin]);

  return token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
