import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { toast } from "react-toastify";
import { BASE_URL, HYDRA_MEMBER } from "../../services/utils/apiRessources";
import { ERROR_MESSAGE } from "./../../services/utils/apiRessources";

const initialState = {
  id: null,
  dateSent: null,
  commentary: null,
  typeOfDoc: null,
  user: null,
  docsList: [],
  treatedDocsList: [],
  myDocsList: [],
  myTreatedDocsList: [],
  isLoading: false,
  isError: null,
  isSuccess: false,
};

const docSlice = createSlice({
  name: "doc",
  initialState,
  reducers: {
    populateDoc: (state, action) => {
      state.id = action.payload.id;
      state.dateSent = action.payload.dateSent;
      state.commentary = action.payload.commentary;
      state.typeOfDoc = action.payload.typeOfDoc;
      state.user = action.payload.user;
    },
    populateDocsList: (state, action) => {
      state.docsList = action.payload.docsList;
    },
    populateTreatedDocsList: (state, action) => {
      state.treatedDocsList = action.payload.treatedDocsList;
    },
    populateMyDocsList: (state, action) => {
      state.myDocsList = action.payload.myDocsList;
    },
    populateMyTreatedDocsList: (state, action) => {
      state.myTreatedDocsList = action.payload.myTreatedDocsList;
    },
    treatDocItemsLists: (state, action) => {
      state.treatedDocsList[HYDRA_MEMBER].unshift(
        action.payload.treatedDocsList
      );
      const notTreatedIndex =
        state.docsList[HYDRA_MEMBER].findIndex(
          (item) => item.id === action.payload.treatedDocsList.id
        );
      if (notTreatedIndex !== -1) {
        state.docsList[HYDRA_MEMBER].splice(
          notTreatedIndex,
          1
        );
      }
    },
    treatMyDocItemsLists: (state, action) => {
      const notTreatedIndex =
        state.myDocsList.findIndex(
          (item) => item.id === action.payload.myTreatedDocsList.id
        );
      if (notTreatedIndex !== -1) {
        state.myDocsList.splice(
          notTreatedIndex,
          1
        );
      }
      state.myTreatedDocsList.unshift(
        action.payload.myTreatedDocsList
      );
    },
    unselectDoc: () => initialState,
    unselectDocList: (state, _action) => {
      state.docsList = null;
    },
    setIsLoadingState: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsErrorState: (state, action) => {
      state.isError = action.payload.isError;
    },
    setIsSuccessState: (state, action) => {
      state.isSuccess = action.payload.isSuccess;
    },
  },
});

const errorMsg = () => {
  return toast.error(ERROR_MESSAGE);
};

export const sendDocRequest = (data, docsArr = [], type = '') => (dispatch) => {
  dispatch(setIsSuccessState({ isSuccess: false }))
  if (docsArr.length === 0 && !type) {
    axios
      .post(`${BASE_URL}/api/ask_for_docs`, data)
      .then((_res) => {
        toast.success("Votre demande a été envoyée avec succés");
        dispatch(setIsSuccessState({ isSuccess: true }));
      })
      .then(() => {
        dispatch(setIsSuccessState({ isSuccess: false }));
      })
      .catch((_err) => {
        toast.error("Une erreur s'est produite lors de l'envoi de la demande");
        dispatch(setIsSuccessState({ isSuccess: false }))
      });
  } else {
    axios
      .post(`${BASE_URL}/api/ask_for_docs`, data)
      .then((res) => {
        dispatch(sendDocRequestFiles(type, res.data.id, docsArr));
        toast.success("Votre demande a été envoyée avec succés");
      })
      .catch((_err) => {
        toast.error("Une erreur s'est produite lors de l'envoi de la demande");
        dispatch(setIsSuccessState({ isSuccess: false }))
      });
  }
};

export const sendDocRequestFiles = (type, askForDoc, mediaObjects) => (dispatch) => {
  const promise = new Promise((_resolve, _reject) => {
    mediaObjects.forEach((file) => {
      axios
        .post(
          `${BASE_URL}/api/ask_for_doc_media_objects`,
          {
            type,
            askForDoc,
            mediaObject: file,
          })
        .catch((_err) => {
          toast.error("Échec de la sauvegarde des fichiers, demande envoyée sans justifications");
          dispatch(setIsSuccessState({ isSuccess: false }))
        })
  
    });
  })
  Promise.all([promise, dispatch(setIsSuccessState({ isSuccess: true })), dispatch(setIsSuccessState({ isSuccess: false })) ])
};

export const fetchDocRequests = (page = 1) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/ask_for_docs?docStatus[]=IN_PROGRESS&page=${page}`)
    .then((res) => {
      dispatch(populateDocsList({ docsList: res.data }));
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de la récupération des demandes de documents en cours");
    });
};

export const fetchTreatedDocRequests = (page = 1) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/ask_for_docs?&docStatus[]=ACCEPTED&docStatus[]=REJECTED&docStatus[]=CANCELED&page=${page}`)
    .then((res) => {
      dispatch(populateTreatedDocsList({ treatedDocsList: res.data }));
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de la récupération des demandes de documents en cours");
    });
};

export const fetchMyDocRequests = (userId) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/ask_for_docs?user.id=${userId}&docStatus[]=IN_PROGRESS`)
    .then((res) => {
      dispatch(populateMyDocsList({ myDocsList: res.data["hydra:member"] }));
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de la récupération de vos demandes de documents en cours");
    });
};

export const fetchMyTreatedDocRequests = (userId) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/ask_for_docs?user.id=${userId}&docStatus[]=ACCEPTED&docStatus[]=REJECTED&docStatus[]=CANCELED`)
    .then((res) => {
      dispatch(populateMyTreatedDocsList({ myTreatedDocsList: res.data["hydra:member"] }));
    })
    .catch((_err) => {
      toast.error("Une erreur s'est produite lors de la récupération de vos demandes de documents traitées");
    });
};

export const respondToDocRequests = (id, docResponse) => (dispatch) => {
  dispatch(setIsLoadingState({ isLoading: true }));
  axios
    .put(`${BASE_URL}/api/ask_for_docs_response/${id}`, { response: docResponse })
    .then((res) => {
      toast.success("vous avez répondu a la demande avec succés");
      dispatch(setIsLoadingState({ isLoading: false }));
      dispatch(treatDocItemsLists({ treatedDocsList: res.data }));
    })
    .catch(() => {
      errorMsg();
      dispatch(setIsLoadingState({ isLoading: false }));
    });
};

export const cancelDocRequest = (id) => (dispatch) => {
  const obj = {
    docStatus: "CANCELED",
  };
  axios
    .put(`${BASE_URL}/api/ask_for_docs/${id}`, obj)
    .then((res) => {
      toast.success("Vous avez annulé votre demande avec succés");
      dispatch(treatMyDocItemsLists({ myTreatedDocsList: res.data }));
    })
    .catch(() => {
      errorMsg();
    });
};

export const selectDoc = (state) => {
  return state.doc;
};

export const selectDocsList = (state) => {
  return state.doc.docsList;
};

export const selectTreatedDocsList = (state) => {
  return state.doc.treatedDocsList;
};

export const selectMyDocsList = (state) => {
  return state.doc.myDocsList;
};

export const selectMyTreatedDocsList = (state) => {
  return state.doc.myTreatedDocsList;
};

export const selectIsErrorState = (state) => {
  return state.doc.isError;
};

export const selectIsLoadingState = (state) => {
  return state.doc.isLoading;
};

export const selectIsSuccessState = (state) => {
  return state.doc.isSuccess;
};

export const {
  populateDoc,
  populateDocsList,
  unselectDoc,
  unselectDocList,
  setIsLoadingState,
  setIsErrorState,
  setIsSuccessState,
  populateMyDocsList,
  populateTreatedDocsList,
  populateMyTreatedDocsList,
  treatDocItemsLists,
  treatMyDocItemsLists,

} = docSlice.actions;

export default docSlice.reducer;
