import React, { useEffect } from "react";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import PendingIcon from "@mui/icons-material/Pending";

import "./leaveRequestStepsPopup.sass";
import { useDispatch, useSelector } from "react-redux";
import { getUserById, selectSelectedUser } from "../../redux/slice/UserSlice";
import { dateHelpers } from "./../../services/utils/dateHelpers";

const MyLeaveInformationCard = ({ askForLeave }) => {
  const dispatch = useDispatch();
  const manager = useSelector(selectSelectedUser);

  useEffect(() => {
    if (askForLeave?.allowedResponders[0]) {
      dispatch(getUserById(askForLeave?.allowedResponders[0]));
    }
  }, [dispatch, askForLeave]);

  return (
    <div className="leaves-info-card">
      <div className="title">Suivi de votre demande</div>
      {askForLeave.treated && (
        <>
          <div className="step-wrapper">
            <PendingIcon color="disabled" fontSize="large" />
            <div className="step-info">
              <div className="step-state">En attente</div>
              <div className="sup-infos">Asma Belkhir</div>
            </div>
          </div>
          <div className="ligne"></div>
        </>
      )}
      <div className="step-wrapper">
        {!askForLeave.treated ? (
          <PendingIcon color="disabled" fontSize="large" />
        ) : (
          <CheckCircleSharpIcon color="success" fontSize="large" />
        )}
        <div className="step-info">
          <div className="step-state">
            {askForLeave.treated ? "Acceptée" : "En attente"}
          </div>
          <div className="sup-infos">
            {manager.firstName}&nbsp;{manager.lastName}
          </div>
          {askForLeave.treated && (
            <div className="sup-infos">
              {askForLeave?.treatedByManagerAt &&
                dateHelpers.formatDateToFrenchFormat(
                  askForLeave?.treatedByManagerAt?.split("T")[0]
                )}
            </div>
          )}
        </div>
      </div>
      <div className="ligne"></div>
      <div className="step-wrapper">
        <CheckCircleSharpIcon color="success" fontSize="large" />
        <div className="step-info">
          <div className="step-state">Effectuée</div>
          <div className="sup-infos">
            {askForLeave?.pendingAt &&
              dateHelpers.formatDateToFrenchFormat(
                askForLeave?.pendingAt?.split("T")[0]
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLeaveInformationCard;
