import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MyLeaveInformationCard from "./MyLeaveInformationCard";
import "./leaveRequestStepsPopup.sass";

const LeaveRequestStepsPopup = ({ askForLeave }) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  return (
    <div className="leave-info-icon-wrapper">
      <div
        className="leaves-info-icon"
        onMouseEnter={() => setDisplayPopup(true)}
        onMouseLeave={() => setDisplayPopup(false)}
      >
        <InfoOutlinedIcon color="disabled" />
      </div>
      <div
        onMouseEnter={() => setDisplayPopup(true)}
        onMouseLeave={() => setDisplayPopup(false)}
      >
        {displayPopup && <MyLeaveInformationCard askForLeave={askForLeave} />}
      </div>
    </div>
  );
};

export default LeaveRequestStepsPopup;
