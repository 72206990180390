import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import { InputTextarea } from "primereact/inputtextarea";
import { fr } from "date-fns/locale";

import {
  fetchtypesOfLeave,
  selectTypesOfleave,
} from "../../redux/slice/TypeOfLeaveSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import {
  fetchLeaveRequestResume,
  sendLeaveRequest,
  selectIsErrorState,
  selectIsLoadingState,
} from "../../redux/slice/LeaveSlice";
import { selectleave } from "./../../redux/slice/LeaveSlice";
import { NO_CANCEL_REQUEST_WARNING } from "../../services/utils/apiRessources";
import ModalFooter from "../modalFooter/ModalFooter";

import "./askForLeaveForm.sass";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("fr", fr);

const AskForLeaveForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listOfTypeOfLeave = useSelector(selectTypesOfleave);
  const userId = useSelector(selectUserId);
  const leave = useSelector(selectleave);
  const isLoadingState = useSelector(selectIsLoadingState);

  const [startDate, setStartDate] = useState(new Date());
  const [timeStartDate, setTimeStartDate] = useState("matin");
  const [endtDate, setEndDate] = useState(new Date());
  const [timeEndDate, setTimeEndDate] = useState("apres_midi");
  const isErrorState = useSelector(selectIsErrorState);
  const [leaveData, setLeaveData] = useState({
    typeOfLeave: "",
    startDate: new Date(),
    endDate: new Date(),
    commentary: "",
    user: "",
  });

  useEffect(() => {
    dispatch(fetchtypesOfLeave(true));
  }, [dispatch]);

  useEffect(() => {
    startDate.setHours(6);
  }, [startDate]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (timeStartDate === "matin") {
      startDate.setHours(1, 0, 0);
    }
    if (timeStartDate === "apres_midi") {
      startDate.setHours(12, 0, 0);
    }
    if (timeEndDate === "matin") {
      endtDate.setHours(12, 0, 0);
    }
    if (timeEndDate === "apres_midi") {
      endtDate.setHours(23, 59, 0);
    }
    const data = {
      ...leaveData,
      user: userId,
      startDate: startDate,
      endDate: endtDate,
    };

    setLeaveData(data);

    dispatch(fetchLeaveRequestResume(data));
  };

  const confirmRequest = () => {
    dispatch(sendLeaveRequest(leaveData, userId));
    navigate("/");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({ ...leaveData, [name]: value });
  };

  return (
    <div className="form-Wrapper">
      <form>
        <div className="mb-3">
          <label className="form-label">1. Type de congé</label>
          <select
            name="typeOfLeave"
            id="typeOfLeave"
            className="form-select"
            onChange={(e) => {
              handleChange(e);
            }}
            value={leaveData.typeOfLeave}
            required
          >
            <option value="">Sélectionnez un type de congé</option>
            {listOfTypeOfLeave?.map((obj, key) => (
              <option
                key={key}
                value={obj.id}
                defaultValue={key === 1 ? 1 : ""}
              >
                {obj.type}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">2. Date de congé</label>
          <div className="date-range-wrapper">
            <div className="datePicker">
              <DatePicker
                className="form-control"
                closeOnScroll={(e) => e.target === document}
                selected={startDate}
                dateFormat={"dd/MM/yyyy"}
                onChange={(date) => {
                  setStartDate(date);
                  date.setHours(12, 0, 0);
                  setEndDate(new Date(date));
                }}
                locale={"fr"}
                minDate={new Date()}
              />
              <select
                className="form-select"
                onChange={(e) => {
                  setTimeStartDate(e.currentTarget.value);
                }}
              >
                <option value="matin">Journée entière</option>
                <option value="matin">Matin</option>
                <option value="apres_midi">Après midi</option>
              </select>
            </div>
            <div className="datePicker">
              <DatePicker
                className="form-control datePicker-input"
                closeOnScroll={(e) => e.target === document}
                selected={endtDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
                locale={"fr"}
                dateFormat={"dd/MM/yyyy"}
                minDate={new Date()}
              />
              <select
                className="form-select"
                onChange={(e) => {
                  setTimeEndDate(e.currentTarget.value);
                }}
              >
                <option value="apres_midi">Journée entière</option>
                <option value="apres_midi">Après midi</option>
                <option value="matin">Matin</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label">3. Motif &nbsp; ({leaveData.commentary.length}/250)</label>
          <InputTextarea
            name="commentary"
            id="commentary"
            value={leaveData.commentary}
            className= "form-control"
            onChange={handleChange}
            rows={5}
            cols={60}
            placeholder="Commentaire"
            autoResize
          />
        </div>
        <button
          type="submit"
          className={`btn ${
            leaveData.typeOfLeave === "" ? "btn-secondary" : "btn-primary"
          }`}
          onClick={(e) => {
            handleFormSubmit(e);
          }}
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          disabled={leaveData.typeOfLeave === ""}
        >
          Envoyer
        </button>
      </form>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="staticBackdropLabel">
                Souhaitez vous valider définitivement votre congé?
              </h6>
            </div>
            <div className="modal-body">
              <div className="resume">
                {isLoadingState ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                ) : (
                  isErrorState ?? (
                    <>
                      <h6>
                        Demande de congé payé de <span>{leave.notPaidDuration}</span>
                        &nbsp; jours
                      </h6>
                      <h6>
                        de <span>{startDate.toLocaleDateString()}</span>&nbsp;
                        au&nbsp;
                        <span>{endtDate.toLocaleDateString()}</span>
                      </h6>
                      <h6>
                        votre solde va diminuer de :&nbsp;
                        <span>{leave.notPaidDuration}</span> jours
                      </h6>
                      <span>{NO_CANCEL_REQUEST_WARNING}</span>
                    </>
                  )
                )}
              </div>
            </div>
            <ModalFooter
              confirmRequest={confirmRequest}
              isErrorState={isErrorState}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskForLeaveForm;
