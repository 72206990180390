import React from "react";
import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import { selectRoles } from "../../redux/slice/UserSlice";
import ValidationsRoute from "../../routes/ValidationsRoute";

import "./validationsPage.sass";

const ValidationsPage = () => {
    const roles = useSelector(selectRoles);

    return (
        <div className="validationPage-wrapper">
            <div className="validationPage-header">
                <div className="title">Des demandes à valider</div>
                <div
                    className="btn-group rounded-pill switch"
                    role="group"
                    aria-label="Basic radio toggle button group"
                >
                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        autoComplete="off"
                        readOnly
                    />
                    <NavLink
                        className="btn btn-outline-dark rounded-pill border border-0"
                        to="/validations/leave-validations"
                    >
                        <label>Congés</label>
                    </NavLink>
                    <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        autoComplete="off"
                        readOnly
                    />
                    {!roles.includes("ROLE_MANAGER") && (
                        <>
                            <input
                                type="radio"
                                className="btn-check"
                                name="btnradio"
                                id="btnradio2"
                                autoComplete="off"
                                readOnly
                            />
                            <NavLink
                                to="/validations/document-validations"
                                className="btn btn-outline-dark rounded-pill border border-0"
                            >
                                <label>Documents</label>
                            </NavLink>
                        </>
                    )}
                </div>
            </div>
            <div className="validations-routes">
                <ValidationsRoute />
            </div>
        </div>
    );
};

export default ValidationsPage;
