import React from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { selectRoles } from "../../redux/slice/UserSlice";

import ArticleIcon from "@mui/icons-material/Article";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";

import "./sideBar.sass";

const SideBar = () => {
  const navigate = useNavigate();
  const roles = useSelector(selectRoles);

  return (
    <div className="sidebar-wrapper">
      <div className="sideBar-header">
        <div
          className="logo-wrapper"
          onClick={() => {
            navigate("/");
          }}
        >
          <div className="logo">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.52159 20.3159H9.43646V24.2266H5.52159V20.3159ZM15.5648 20.3159H19.4796V24.2266H15.5648V20.3159ZM0.5 15.2946H4.41487V19.2039H0.5V15.2946ZM5.52159 15.2946H9.43646V19.2039H5.52159V15.2946ZM10.5432 15.2946H14.4581V19.2039H10.5432V15.2946ZM15.5648 15.2946H19.4796V19.2039H15.5648V15.2946ZM20.5864 15.2946H24.5012V19.2039H20.5864V15.2946ZM5.52159 10.2719H9.43646V14.1812H5.52159V10.2719ZM15.5648 10.2719H19.4796V14.1812H15.5648V10.2719ZM0.5 5.24923H4.41487V9.15856H0.5V5.24923ZM5.52159 5.24923H9.43646V9.15856H5.52159V5.24923ZM10.5432 5.24923H14.4581V9.15856H10.5432V5.24923ZM15.5648 5.24923H19.4796V9.15856H15.5648V5.24923ZM20.5864 5.24923H24.5012V9.15856H20.5864V5.24923ZM5.52159 0.226562H9.43646V4.1359H5.52159V0.226562ZM19.4796 0.226562V4.1359H15.5648V0.226562H19.4796Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="logo-title">TunisRH</div>
        </div>
      </div>
      <ul className="sideBar-content">
        <NavLink to="/" className="sideBar-button">
          <div className="button-content">
            <span className="btn-icon">
              <HomeIcon />
            </span>
            <div className="btn-text">Mon dashboard</div>
          </div>
        </NavLink>
        <NavLink to="/my-leaves/my-leaves-requests" className="sideBar-button">
          <div className="button-content">
            <span className="btn-icon">
              <DateRangeIcon />
            </span>
            <div className="btn-text">Mes congés</div>
          </div>
        </NavLink>
        <NavLink to="/my-documents" className="sideBar-button">
          <div className="button-content">
            <span className="btn-icon">
              <ArticleIcon />
            </span>
            <div className="btn-text">Mes documents</div>
          </div>
        </NavLink>
        <NavLink to="/my-team" className="sideBar-button">
          <div className="button-content">
            <span className="btn-icon">
              <GroupsIcon />
            </span>
            <div className="btn-text">Mon équipe</div>
          </div>
        </NavLink>
        <hr />
        {(roles?.includes("ROLE_ADMIN") ||
          roles?.includes("ROLE_MANAGER") ||
          roles?.includes("ROLE_RH")) && (
          <NavLink
            to="/validations/leave-validations"
            className="sideBar-button"
          >
            <div className="button-content">
              <span className="btn-icon">
                <FactCheckIcon />
              </span>
              <div className="btn-text">Validations</div>
            </div>
          </NavLink>
        )}
        {(roles?.includes("ROLE_ADMIN") ||
          roles?.includes("ROLE_RH")) && (
          <NavLink
            to="/configurations/type-of-leave"
            className="sideBar-button"
          >
            <div className="button-content">
              <span className="btn-icon">
                <SettingsIcon />
              </span>
              <div className="btn-text">Configurations</div>
            </div>
          </NavLink>
        )}
      </ul>
      <ul className="sideBar-footer">
        <NavLink to="/my-account" className="sideBar-button">
          <div className="button-content">
            <span className="btn-icon">
              <PersonIcon />
            </span>
            <div className="btn-text">Mon compte</div>
          </div>
        </NavLink>
        <Link to="/login" className="sideBar-button">
          <div className="button-content">
            <span className="btn-icon">
              <LogoutIcon />
            </span>
            <div className="btn-text">Se déconnecter</div>
          </div>
        </Link>
      </ul>
    </div>
  );
};

export default SideBar;
