import React from "react";
import { dateHelpers } from "../../services/utils/dateHelpers";

const MyLeavesList = ({ myLeavesList }) => {
  return (
    <>
      {myLeavesList?.length <= 0 ? (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas d'historique des demandes
        </div>
      ) : (
        <table className="table table-borderless">
          <tbody>
            {myLeavesList?.map(
              (obj, key) =>
                key < 5 && (
                  <tr key={key}>
                    <td>{obj.typeOfLeave.type}</td>
                    <td>
                      {`${dateHelpers.formatDateToFrenchFormat(
                        obj.startDate.split("T")[0]
                      )} - ${dateHelpers.formatDateToFrenchFormat(
                        obj.endDate.split("T")[0]
                      )}`}
                    </td>
                    {obj.finalStatus === "IN_PROGRESS" && (
                      <td className="inProgress">En cours</td>
                    )}
                    {obj.finalStatus === "ACCEPTED" && (
                      <td className="accepted">Accepté</td>
                    )}
                    {obj.finalStatus === "REJECTED" && (
                      <td className="rejected">Refusé</td>
                    )}
                    {obj.finalStatus === "CANCELED" && (
                      <td className="canceled">Annulé</td>
                    )}
                  </tr>
                )
            )}
          </tbody>
        </table>
      )}
    </>
  );
};

export default MyLeavesList;
