import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  selectLeaveBalance,
} from "./../../redux/slice/UserSlice";
import NotificationIcon from "./../notificationIcon/NotificationIcon";

import "./navbar.sass";

const Navbar = () => {
  const leaveBalance = useSelector(selectLeaveBalance);

  return (
    <div className="navbar">
      <div className="solde">
        <span className="d-none d-md-block">Votre solde est</span>
        <h5>
          {leaveBalance}
        </h5>
        <span>jours</span>
      </div>
      <div className="right-side">
        <NotificationIcon />
        <div className="btn-group btn-group-sm" role="group">
          <button
            id="btnGroupDrop1"
            type="button"
            className="btn btn-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Créer une demande
          </button>
          <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
            <li>
              <NavLink to={"/asks/ask-for-leave"} className="dropdown-item">
              Congé
              </NavLink>
            </li>
            <li>
              <NavLink to={"/ask-for-document"} className="dropdown-item">
                Document
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
