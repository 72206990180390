import { Avatar } from "primereact/avatar";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchLeaveRequestsByUser,
  selectleaveListByUserId,
} from "../../redux/slice/LeaveSlice";
import { HYDRA_MEMBER } from "../../services/utils/apiRessources";
import { dateHelpers } from "./../../services/utils/dateHelpers";

const UserInformationsCard = ({ user }) => {
  const dispatch = useDispatch();
  const leaveList = useSelector(selectleaveListByUserId);
  const initials = `${user.firstName[0] || ""}${user.lastName[0] || ""
    }`.toUpperCase();

  const generatePositionTitle = (positions) => {
    return positions.reduce((prev, current, index) => {
      if (index > 0 && !prev.includes(current.position)) {
        prev += `${index < positions.length - 1 ? "" : " & "}${current.position
          }`;
      }

      return prev;
    }, positions[0].position);
  };

  useEffect(() => {
    dispatch(fetchLeaveRequestsByUser(user.id));
  }, [dispatch, user.id]);

  return (
    <div className="info-card">
      <div className="avatar_wrapper">
        <div
          className="img"
          style={{
            border: `1px solid ${user.leaveBalance > 0 ? "#87DB32" : "#ff4d4d"
              }`,
          }}
        >
          <Avatar
            label={initials}
            size="large"
            shape="circle"
            style={{ fontSize: 20 }}
          />
        </div>
        <div className="info-userName">
          {user.firstName}&nbsp;{user.lastName}
        </div>
        <div className="info-position">
          {generatePositionTitle(user.positions)}
        </div>
      </div>
      <div className="informations-wrapper">
        <div className="informations">
          <div>Solde de congés</div>
          <div>{user.leaveBalance}</div>
        </div>
        <div className="informations">
          <div>Dernier congé</div>
          <div>
            {leaveList[HYDRA_MEMBER] &&
              leaveList[HYDRA_MEMBER].find(
                (obj) => obj.finalStatus === "ACCEPTED"
              )
              ? dateHelpers.formatDateToFrenchFormat(
                leaveList[HYDRA_MEMBER].find(
                  (obj) => obj.finalStatus === "ACCEPTED"
                )?.endDate?.split("T")[0]
              )
              : "Pas encore"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformationsCard;
