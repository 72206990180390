import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import MyDocumentsPage from "../pages/myDocumentsPage/MyDocumentsPage";
import MyAccount from "../pages/myAccount/MyAccount";
import AskForLeavePage from "../pages/askForLeavePage/AskForLeavePage";
import AskForAuthorizationPage from "../pages/askForAthorizationPage/AskForAuthorizationPage";
import MyTeamPage from "../pages/myTeamPage/MyTeamPage";
import PrivateRoute from "./auth/PrivateRoute";
import DashboardPage from "../pages/dashboardPage/DashboardPage";
import ValidationsPage from "../pages/validationsPage/ValidationsPage";
import MyRequestsPage from "../pages/myRequestsPage/MyRequestsPage";
import ConfigurationsPage from './../pages/configurationsPage/ConfigurationsPage';
import { AskForDoc } from "../pages/askForDocument/AskForDoc";
import UserDetailsPage from "../pages/userDetailsPage/UserDetailsPage";

const HomeRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-leaves/*"
        element={
          <PrivateRoute>
            <MyRequestsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-documents"
        element={
          <PrivateRoute>
            <MyDocumentsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-account"
        element={
          <PrivateRoute>
            <MyAccount />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/asks/*"
        element={
          <PrivateRoute>
            <AskForLeavePage />
          </PrivateRoute>
        }
      />
      <Route
        path="/ask-for-document"
        element={
          <PrivateRoute>
            <AskForDoc />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-team"
        element={
          <PrivateRoute>
            <MyTeamPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-team/user/:userId"
        element={
          <PrivateRoute>
            <UserDetailsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="validations/*"
        element={
          <PrivateRoute>
            <ValidationsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="configurations/*"
        element={
          <PrivateRoute>
            <ConfigurationsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="ask-for-authorization"
        element={
          <PrivateRoute>
            <AskForAuthorizationPage />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default HomeRoute;
