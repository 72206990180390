import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotTreatedAuthorizationRequests,
  fetchNotTreatedReceivedAuthorizationRequests,
  respondToAuthorizationRequests,
} from "../../redux/slice/AuthorizationSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import {
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import Paginator from "../paginator/Paginator";
import ModalContent from "./ModalContent";

const NotTreatedReceivedAuthorizationRequestsTable = ({
  notTreatedReceivedAuthorizationRequests,
  roles,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  const respondToLeaveRequest = (data) => {
    dispatch(respondToAuthorizationRequests(data, roles));
  };

  const clickPagination = (page) => {
    if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) {
      dispatch(fetchNotTreatedAuthorizationRequests(page));
    } else if (userId) {
      dispatch(fetchNotTreatedReceivedAuthorizationRequests(userId, page));
    }
  };

  return (
    <>
      {notTreatedReceivedAuthorizationRequests[HYDRA_MEMBER]?.length > 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">De</th>
                <th scope="col">Date</th>
                <th scope="col">Temps</th>
                <th scope="col">État</th>
              </tr>
            </thead>
            <tbody>
              {notTreatedReceivedAuthorizationRequests[HYDRA_MEMBER]?.map(
                (obj, key) => (
                  <tr key={key}>
                    <td className="name_column">
                      <div className="cell_name">{`${obj.user.firstName} ${obj.user.lastName}`}</div>
                    </td>
                    <td>
                      {dateHelpers.formatDateToFrenchFormat(
                        obj.startDate.split("T")[0]
                      )}
                    </td>
                    <td>
                      {`${dateHelpers.getTimeFromDate(
                        obj.startDate,
                        true
                      )} - ${dateHelpers.getTimeFromDate(obj.endDate, true)}`}
                    </td>
                    {obj.treated && !roles.includes("ROLE_ADMIN") ? (
                      <td className="response_wrapper">
                        <div className="inProgress">En cours</div>
                      </td>
                    ) : (
                      <td>
                        <div
                          className="verification_btn"
                          data-bs-toggle="modal"
                          data-bs-target={`#staticBackdrop${key}11Authorization`}
                        >
                          Vérifier &gt;
                        </div>
                      </td>
                    )}
                    <td
                      className="modal fade"
                      id={`staticBackdrop${key}11Authorization`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <ModalContent obj={obj} respondToLeaveRequest={respondToLeaveRequest} />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <Paginator
            totalItems={
              notTreatedReceivedAuthorizationRequests[HYDRA_TOTALITEMS]
            }
            paginate={clickPagination}
          />
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas des Demandes en cours
        </div>
      )}
    </>
  );
};

export default NotTreatedReceivedAuthorizationRequestsTable;
