import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  selectAuthorizationBalance,
  selectLeaveBalance,
} from "./../../redux/slice/UserSlice";
import RequestsRoute from "./../../routes/RequestsRoute";

import "./askForLeavePage.sass";

const AskForLeavePage = () => {
  const leaveBalance = useSelector(selectLeaveBalance);
  const authorizationBalance = useSelector(selectAuthorizationBalance);

  return (
    <div className="askForLeavePage-wrapper">
      <div className="title">Effectuer une demande</div>
      <div className="content">
        <div className="score-wrapper">
          <div className="score-title">Votre solde actuel</div>
          <div className="score-content">
            <div className="leave">
              <div className="score">{leaveBalance} jours</div>
              Solde de congés
            </div>
          </div>
        </div>
        <div className="form">
          <div className="navigation-form">
            <RequestsRoute />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskForLeavePage;
