import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDocRequests,
  fetchTreatedDocRequests,
  selectDocsList,
  selectTreatedDocsList,
} from "../../redux/slice/docSlice";
import { selectRoles, selectUserId } from "../../redux/slice/UserSlice";

import "./documentValidation.sass";
import NotTreatedDocRequests from "./notTreatedDocRequests";
import TreatedDocRequests from "./treatedDocRequests";

const DocumentValidation = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const roles = useSelector(selectRoles);
  const notTreated = useSelector(
    selectDocsList
  );
  const treated = useSelector(
    selectTreatedDocsList
  );

  useEffect(() => {
    if (userId && (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH"))) {
      dispatch(fetchDocRequests());
      dispatch(fetchTreatedDocRequests());
    }
  }, [userId, dispatch, roles]);

  return (
    <div className="DocValidation-wrapper">
      {!roles.includes("ROLE_MANAGER") && (
        <>
          <label>Demandes en cours</label>
          <NotTreatedDocRequests
            notTreated={notTreated}
            roles={roles}
          />
        </>
      )}

      <label>Demandes traitées</label>

      <TreatedDocRequests
        treated={treated}
        roles={roles}
      />
    </div>
  );
};

export default DocumentValidation;
