import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTreatedAuthorizationRequests, fetchNotTreatedAuthorizationRequests, fetchNotTreatedReceivedAuthorizationRequests, fetchTreatedReceivedAuthorizationRequests, selectNotTreatedReceivedAuthorizationRequests, selectTreatedReceivedAuthorizationRequests } from "../../redux/slice/AuthorizationSlice";
import { selectRoles, selectUserId } from "../../redux/slice/UserSlice";
import NotTreatedReceivedAuthorizationRequestsTable from "./NotTreatedReceivedAuthorizationRequestsTable";
import TreatedReceivedAuthorizationRequests from "./TreatedReceivedAuthorizationRequests";

const AuthorizationValidation = () => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const roles = useSelector(selectRoles);
  const notTreatedReceivedAuthorizationRequests = useSelector(
    selectNotTreatedReceivedAuthorizationRequests
  );
  const treatedReceivedAuthorizationRequests = useSelector(
    selectTreatedReceivedAuthorizationRequests
  );

  useEffect(() => {
    if (userId && (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH"))) {
      dispatch(fetchNotTreatedAuthorizationRequests());
      dispatch(fetchAllTreatedAuthorizationRequests());
    } else if (userId) {
      dispatch(fetchNotTreatedReceivedAuthorizationRequests(userId));
      dispatch(fetchTreatedReceivedAuthorizationRequests(userId));
    }
  }, [userId, dispatch, roles]);

  return (
    <div className="LeaveValidation-wrapper">
      {!roles.includes("ROLE_RH") && (
        <>
          <label>Demandes en cours</label>
          <NotTreatedReceivedAuthorizationRequestsTable roles={roles} notTreatedReceivedAuthorizationRequests={notTreatedReceivedAuthorizationRequests} />
        </>
      )}

      <label>Demandes traitées</label>

      <TreatedReceivedAuthorizationRequests roles={roles} treatedReceiveaAuthorizationRequests={treatedReceivedAuthorizationRequests} />
    </div>
  );
};

export default AuthorizationValidation;
