import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectTeammates, getUserById, selectSelectedUser } from "../../redux/slice/UserSlice";
import UserLeaveTable from "../../components/userLeavesTable/UserLeaveTable";
import "./userDetailsPage.sass";

const UserDetailsPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const colleagues = useSelector(selectTeammates);
  const selectedUser = useSelector(selectSelectedUser);

  let user = colleagues.find((user) => user.id.toString() === userId);

  useEffect(() => {
    if (!user) {
      dispatch(getUserById(userId));
    }
  }, [userId, user, dispatch]);

  if (!user && !selectedUser.id) {
    return <div>Loading...</div>;
  }

  if (!user) {
    user = selectedUser;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div className="userDetailsPage">
      <div className="header">
        <button className="backButton" onClick={() => navigate(-1)}>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="32" 
            height="32" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="black" 
            stroke-width="1" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            class="feather feather-arrow-left-circle">
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 8 8 12 12 16"></polyline>
              <line x1="16" y1="12" x2="8" y2="12"></line>
          </svg>
        </button>
        <div className="h2-Montserrat">{`Les congés de ${user.firstName} ${user.lastName}`}</div>
      </div>
      <UserLeaveTable leaves={user.askForLeaves} />
    </div>
  );
};

export default UserDetailsPage;