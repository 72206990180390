import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dropdown } from "primereact/dropdown";
import Divider from "@mui/material/Divider";
import {
  fetchTeamsList,
  selectTeamsList,
  addTeam,
  deleteTeam,
  addPosition,
  deletePosition,
} from "../../redux/slice/teamsSlice";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./teamAndPosition.sass";

const TeamAndPosition = () => {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeamsList);

  const [newPos, setNewPos] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [positionOptions, setPositionOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchTeamsList());
  }, [dispatch]);

  useEffect(() => {
    if (typeof selectedTeam === "string") {
      const updatedTeam = teams.find((team) => team.name === selectedTeam);
      updatedTeam?.id && setSelectedTeam(updatedTeam);
    }
    if (typeof selectedTeam !== "string") {
      const updatedTeam = teams.find((team) => team.id === selectedTeam.id);
      setSelectedTeam(updatedTeam);
    }
  }, [teams, selectedTeam]);

  useEffect(() => {
    if (typeof selectedTeam !== "string") {
      setPositionOptions(selectedTeam?.positions || []);
      setNewPos("");
    }
  }, [selectedTeam]);

  const teamNameExists = (name) => {
    return teams.some((el) => {
      return el.name.toString().toLowerCase() === name.toString().toLowerCase();
    });
  };

  const isRemovableTeam = () => {
    if (teams?.includes(selectedTeam) && selectedTeam.positions?.length === 0) {
      return true;
    }
  };

  const handleTeamSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: selectedTeam,
    };
    dispatch(addTeam(data));
  };

  const handlePositionSubmit = (e) => {
    e.preventDefault();
    const data = {
      position: newPos,
      team: selectedTeam.id,
    };
    dispatch(addPosition(data));
  };

  return (
    <div className="teamAndPosConfigWrapper">
      <div className="teamAndPosConfig mt-4">
        <div className="addTeamForm">
          <label>1. Ajouter une équipe</label>
          <div className="remTeam">
            <Dropdown
              value={selectedTeam}
              options={teams}
              optionLabel="name"
              onChange={(e) => setSelectedTeam(e.value)}
              placeholder="Ajouter ou selectionner une équipe"
              editable
              style={{ flex: 18 }}
            />
            {selectedTeam.id && isRemovableTeam() && (
              <button
                className="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target={`#deleteTypeOfDoc${selectedTeam.id}`}
                style={{ flex: 1 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
            )}
          </div>
          {selectedTeam.id && (
            <div
              className="modal fade"
              id={`deleteTypeOfDoc${selectedTeam.id}`}
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <h6>Vous êtes sûr de supprimer une équipe ?</h6>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      data-bs-dismiss="modal"
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        dispatch(deleteTeam(selectedTeam.id));
                        setSelectedTeam("");
                      }}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            className={`btn ${
              selectedTeam.length < 2 ? "btn-secondary" : "btn-primary"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#addTeam"
            disabled={
              selectedTeam.length < 1 ||
              teams?.includes(selectedTeam) ||
              teamNameExists(selectedTeam)
            }
          >
            Ajouter une équipe
          </button>
        </div>
        <div className="SeperatorLine">
          <Divider orientation="vertical"> </Divider>
        </div>
        <div className="addPositionForm">
          <label>2. Ajouter une position</label>
          {positionOptions.map((position, index) => (
            <div className="addPos" key={index}>
              <input
                required
                type="text"
                name="position"
                className="form-control"
                style={{ flex: 4 }}
                value={position.position}
                disabled
              />
              <button
                className="btn btn-outline-danger"
                data-bs-toggle="modal"
                data-bs-target={`#deletePosition${position.id}`}
                style={{ flex: 1 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </button>
              <div
                className="modal fade"
                id={`deletePosition${position.id}`}
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <h6>{`Vous êtes sûr de supprimer la position "${position.position}" de "${selectedTeam.name}"?`}</h6>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        data-bs-dismiss="modal"
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          dispatch(
                            deletePosition(position.id, selectedTeam.id)
                          );
                        }}
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="addPos">
            <input
              required
              type="text"
              name="newPos"
              className="form-control"
              placeholder="Ajouter une position"
              value={newPos}
              onChange={(e) => setNewPos(e.target.value)}
              style={{ flex: 4 }}
            />
            <button
              className="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#addPosition"
              disabled={newPos.length < 1 || !teams.includes(selectedTeam)}
              style={{ flex: 1 }}
            >
              + Position
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addPosition"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h6>{`Vous êtes sûr d'ajouter ${newPos} au ${selectedTeam.name} ?`}</h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  handlePositionSubmit(e);
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addTeam"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h6>{`Vous êtes sûr d'ajouter "${selectedTeam}" au équipes ?`}</h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  handleTeamSubmit(e);
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="displayedData"></div>
    </div>
  );
};

export default TeamAndPosition;
