import React from "react";
import { dateHelpers } from "../../services/utils/dateHelpers";
import Paginator from "../paginator/Paginator";
import {
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../redux/slice/UserSlice";
import moment from "moment";
import { fetchMyTreatedAuthorizationRequests } from "../../redux/slice/AuthorizationSlice";
import { cancelAcceptedAuthorizationRequest } from "./../../redux/slice/AuthorizationSlice";

const MyTreadtedAuthorizationListTable = ({ myTreatedAuthorizationList }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  const cancelMyAuthorizationRequest = (id) => {
    dispatch(cancelAcceptedAuthorizationRequest(id));
  };

  const clickPagination = (page) => {
    dispatch(fetchMyTreatedAuthorizationRequests(userId, page));
  };

  return (
    <>
      {myTreatedAuthorizationList[HYDRA_MEMBER]?.length !== 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">Durée</th>
                <th scope="col">Date</th>
                <th scope="col">Temps</th>
                <th scope="col">Etat</th>
              </tr>
            </thead>
            <tbody>
              {myTreatedAuthorizationList[HYDRA_MEMBER]?.map((obj, key) => (
                <tr key={key}>
                  <td>{obj.duration}h</td>
                  <td>
                    {dateHelpers.formatDateToFrenchFormat(
                      obj.startDate.split("T")[0]
                    )}
                  </td>
                  <td>
                    {dateHelpers.getTimeFromDate(obj.startDate, true)}
                    &nbsp;-&nbsp;
                    {dateHelpers.getTimeFromDate(obj.endDate, true)}
                  </td>
                  {obj.finalStatus === "ACCEPTED" && (
                    <td className="response_wrapper">
                      {moment(obj.startDate).isAfter() ? (
                      <div
                        className="accepted_cancel"
                        data-bs-toggle="modal"
                        data-bs-target={`#staticBackdrop${key}Authorization`}
                      >
                        Accepté
                      </div>
                      ) : (<div className="accepted">Accepté</div>
                      )}
                    </td>
                  )}
                  {obj.finalStatus === "REJECTED" && (
                    <td className="response_wrapper">
                      <div className="rejected">Refusé</div>
                    </td>
                  )}
                  {obj.finalStatus === "CANCELED" && (
                    <td className="response_wrapper">
                      <div className="rejected">Annulé</div>
                    </td>
                  )}
                  <td
                    className="modal fade"
                    id={`staticBackdrop${key}Authorization`}
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <div className="modal-body">
                          <h5>Vous étes sur d'annuler votre demande ?</h5>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            data-bs-dismiss="modal"
                          >
                            Refuser
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              cancelMyAuthorizationRequest(obj.id);
                            }}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            totalItems={myTreatedAuthorizationList[HYDRA_TOTALITEMS]}
            paginate={clickPagination}
          />
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas encore des demandes traitées
        </div>
      )}
    </>
  );
};

export default MyTreadtedAuthorizationListTable;
