import React from "react";
import PropTypes from "prop-types";
import "./userLeaveTable.sass";

const UserLeaveTable = ({ leaves }) => {
  if (leaves.length === 0) {
    return (
      <div className="alert alert-info" role="alert">
        Aucun congé trouvé pour cet utilisateur.
      </div>
    );
  }

  return (
    <div className="leaveTable">
      <table className="table">
        <thead>
          <tr>
            <th>Type de congé</th>
            <th>Début</th>
            <th>Fin</th>
            <th>Jours posés</th>
          </tr>
        </thead>
        <tbody>
          {leaves.map((leave, index) => (
            <tr key={index}>
              <td>{leave.typeOfLeave.type}</td>
              <td>{new Date(leave.startDate).toLocaleDateString()}</td>
              <td>{new Date(leave.endDate).toLocaleDateString()}</td>
              <td>{`${leave.duration} jours`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

UserLeaveTable.propTypes = {
  leaves: PropTypes.arrayOf(
    PropTypes.shape({
      typeOfLeave: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default UserLeaveTable;