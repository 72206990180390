import React from "react";
import {Route, Navigate, Routes } from "react-router-dom";
import PrivateRoute from "./auth/PrivateRoute";
import TypeOfDoc from "./../components/typeOfDoc/TypeOfDoc";
import TypeOfLeave from "./../components/typeOfLeave/TypeOfLeave";
import Holiday from './../components/holiday/Holiday';
import TeamAndPosition from "../components/teamAndPosConfig/TeamAndPosition";

const ConfigurationsRoute = () => {
  return (
    <Routes>
      <Route
        path="type-of-leave"
        element={
          <PrivateRoute>
            <TypeOfLeave />
          </PrivateRoute>
        }
      />
      <Route
        path="holidays"
        element={
          <PrivateRoute>
            <Holiday />
          </PrivateRoute>
        }
      />
      <Route
        path="type-of-docs"
        element={
          <PrivateRoute>
            <TypeOfDoc />
          </PrivateRoute>
        }
      />
      <Route
        path="team-and-position"
        element={
          <PrivateRoute>
            <TeamAndPosition />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default ConfigurationsRoute;
