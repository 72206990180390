import axios from "axios";

const setup = () => {
  const token = localStorage.getItem("token");
  const exp = parseInt(localStorage.getItem("exp"));

  if (token) {
    if (exp * 1000 > new Date().getTime()) {
      axios.defaults.headers["Authorization"] = "Bearer " + token;
    } else {
      window.location.replace("/login")
    }
  }
};

export const authService = {
  setup,
};
