import React from "react";

import moment from "moment";
import "moment/locale/fr";

import { useDispatch } from "react-redux";
import { markNotificationAsSeen } from "../../redux/slice/NotificationSlice";

import "./notificationItem.sass";

const NotificationItem = ({ obj }) => {
  moment.locale("fr");
  const dispatch = useDispatch();
  return (
    <div
      className="notificationItem_wrapper"
      onMouseLeave={() => {
        dispatch(markNotificationAsSeen(obj.id));
      }}
    >
      <div className="notificationItem_indicator_wrapper">
        <div
          className="notificationItem_indicator"
          style={{ backgroundColor: obj.isSeen ? "grey" : "blue" }}
        >
          &nbsp;
        </div>
      </div>
      <div className="notificationItem_content">
        <label>{obj.content}</label>
        <span className="dateTime">{moment(obj.createdAt).format("LLL")}</span>
      </div>
    </div>
  );
};

export default NotificationItem;
