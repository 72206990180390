import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL, HYDRA_DESCRIPTION, HYDRA_MEMBER } from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import { buildFilters } from "../../services/utils/filterHelpers";
import { dispatchUser, updateTeamMatesList } from "./UserSlice";
import { ERROR_MESSAGE } from "./../../services/utils/apiRessources";

const initialState = {
  id: null,
  startDate: null,
  endDate: null,
  commentary: null,
  numberOfWeekendDays: null,
  numberOfHolidays: null,
  duration: null,
  numberOfNotCountableDays: null,
  typeOfLeave: null,
  notPaidDuration: null,
  finalStatus: null,
  user: null,
  leavesList: [],
  myLeavesList: [],
  myTreatedLeaveList: {},
  leaveListByUserId: {},
  notTreatedReceivedLeaveRequests: {},
  treatedReceivedLeaveRequests: {},
  isLoading: false,
  isError: null,
  isSuccess: false,
};

const LeaveSlice = createSlice({
  name: "Leave",
  initialState,
  reducers: {
    populateLeave: (state, action) => {
      state.id = action.payload.id;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
      state.commentary = action.payload.commentary;
      state.numberOfWeekendDays = action.payload.numberOfWeekendDays;
      state.numberOfHolidays = action.payload.numberOfHolidays;
      state.duration = action.payload.duration;
      state.notPaidDuration = action.payload.notPaidDuration;
      state.numberOfNotCountableDays = action.payload.numberOfNotCountableDays;
      state.finalStatus = action.payload.finalStatus;
      state.typeOfLeave = action.payload.typeOfLeave;
      state.user = action.payload.user;
    },
    setLeaveList: (state, action) => {
      state.leavesList = action.payload.leavesList;
    },
    populateMyLeaveList: (state, action) => {
      state.myLeavesList = action.payload.myLeavesList;
    },
    setMyTreatedLeaveList: (state, action) => {
      state.myTreatedLeaveList = action.payload.myTreatedLeaveList;
    },
    setNotTreatedReceivedLeaveRequests: (state, action) => {
      state.notTreatedReceivedLeaveRequests =
        action.payload.notTreatedReceivedLeaveRequests;
    },
    setTreatedReceivedLeaveRequests: (state, action) => {
      state.treatedReceivedLeaveRequests =
        action.payload.treatedReceivedLeaveRequests;
    },
    treatLeaveItemInLists: (state, action) => {
      if (action.payload.roles.includes("ROLE_ADMIN")) {
        state.treatedReceivedLeaveRequests[HYDRA_MEMBER].unshift(
          action.payload.treatedReceivedLeaveRequests
        );

        const notTreatedRequestLeaveIndex =
          state.notTreatedReceivedLeaveRequests[HYDRA_MEMBER].findIndex(
            (item) => item.id === action.payload.treatedReceivedLeaveRequests.id
          );
        if (notTreatedRequestLeaveIndex !== -1) {
          state.notTreatedReceivedLeaveRequests[HYDRA_MEMBER].splice(
            notTreatedRequestLeaveIndex,
            1
          );
        }
      }

      if (action.payload.roles.includes("ROLE_MANAGER")) {
        const index = state.notTreatedReceivedLeaveRequests[
          HYDRA_MEMBER
        ].findIndex(
          (item) => item.id === action.payload.treatedReceivedLeaveRequests.id
        );

        if (index !== -1) {
          state.notTreatedReceivedLeaveRequests[HYDRA_MEMBER][index] =
            action.payload.treatedReceivedLeaveRequests;
        }
      }
    },
    updateLeaveItemInMyList: (state, action) => {
      const { updatedLeaveRequest } = action.payload;

      const updatedItemIndex = state.myTreatedLeaveList[HYDRA_MEMBER].findIndex(
        (leaveItem) => leaveItem.id === updatedLeaveRequest.id
      );
      if (updatedItemIndex !== -1) {
        state.myTreatedLeaveList[HYDRA_MEMBER][updatedItemIndex] =
          updatedLeaveRequest;
      }
    },
    unselectLeave: () => initialState,
    unselectLeaveList: (state, _action) => {
      state.leavesList = null;
    },
    setLeaveListByUserId: (state, action) =>{
      state.leaveListByUserId = action.payload.leaveListByUserId;
    },
    setIsLoadingState: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsErrorState: (state, action) => {
      state.isError = action.payload.isError;
    },
    setIsSuccessState: (state, action) => {
      state.isSuccess = action.payload.isSuccess;
    },
  },
});

const errorMsg = () => {
  return toast.error(ERROR_MESSAGE);
};

export const sendLeaveRequest = (data, userId) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/ask_for_leaves`, data)
    .then(() => {
      dispatch(dispatchUser());
      toast.success("Votre demande a été envoyée avec succés");
      dispatch(
        fetchMyRequests({
          "user.id": userId,
          page: 1,
        })
      );
    })
    .catch(() => {
      errorMsg();
    });
};

export const sendLeaveForUser = (data) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/ask_for_leaves/addLeaveForUser`, data)
    .then((res) => {
      dispatch(updateTeamMatesList({ teammates: res.data.user}));
      toast.success("Le congé a été ajouté avec succés");
    })
    .catch((err) => {
      toast.error(err.response.data[HYDRA_DESCRIPTION]);
    });
};

export const fetchMyRequests =
  (queryParams = {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(`${BASE_URL}/api/ask_for_leaves${buildFilters(queryParams)}`)
      .then((res) => {
        dispatch(populateMyLeaveList({ myLeavesList: res.data[HYDRA_MEMBER] }));
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
      });
  };

export const fetchAllAcceptedleaveRequests = (_userId,startDate,endDate) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/ask_for_leaves?dateRange=${startDate}..${endDate}&finalStatus=ACCEPTED`)
    .then((res) => {
      dispatch(setLeaveList({ leavesList: res.data[HYDRA_MEMBER] }));
    })
    .catch(() => {
      errorMsg();
    });
};

export const fetchMyTreatedleaveRequests =
  (userId, page = 1) =>
  (dispatch) => {
    axios
      .get(
        `${BASE_URL}/api/ask_for_leaves?user.id=${userId}&finalStatus[]=REJECTED&finalStatus[]=ACCEPTED&finalStatus[]=CANCELED&page=${page}`
      )
      .then((res) => {
        dispatch(setMyTreatedLeaveList({ myTreatedLeaveList: res.data }));
      })
      .catch(() => {
        errorMsg();
      });
  };

export const fetchNotTreatedReceivedLeaveRequests =
  (userId, page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_leaves?allowedResponders=:${userId};&finalStatus[]=IN_PROGRESS&page=${page}`
      )
      .then((res) => {
        dispatch(
          setNotTreatedReceivedLeaveRequests({
            notTreatedReceivedLeaveRequests: res.data,
          })
        );
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
      });
  };

  export const fetchLeaveRequestsByUser =
  (userId, page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_leaves?user.id=${userId}`
      )
      .then((res) => {
        dispatch(
          setLeaveListByUserId({
            leaveListByUserId: res.data,
          })
        );
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
      });
  };

export const fetchNotTreatedLeaveRequests =
  (page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_leaves?finalStatus[]=IN_PROGRESS&page=${page}`
      )
      .then((res) => {
        const data = {
          notTreatedReceivedLeaveRequests: res.data,
        };
        dispatch(setNotTreatedReceivedLeaveRequests(data));
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
      });
  };

export const fetchAllTreatedLeaveRequests =
  (page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_leaves?&finalStatus[]=REJECTED&finalStatus[]=ACCEPTED&finalStatus[]=CANCELED&page=${page}`
      )
      .then((res) => {
        dispatch(
          setTreatedReceivedLeaveRequests({
            treatedReceivedLeaveRequests: res.data,
          })
        );
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
      });
  };

export const fetchTreatedReceivedLeaveRequests =
  (userId, page = 1) =>
  (dispatch) => {
    dispatch(setIsLoadingState({ isLoading: true }));
    axios
      .get(
        `${BASE_URL}/api/ask_for_leaves?allowedResponders=:${userId};&finalStatus[]=ACCEPTED&finalStatus[]=REJECTED&finalStatus[]=CANCELED&page=${page}`
      )
      .then((res) => {
        const data = {
          treatedReceivedLeaveRequests: res.data,
        };
        dispatch(setTreatedReceivedLeaveRequests(data));
        dispatch(setIsLoadingState({ isLoading: false }));
      })
      .catch(() => {
        errorMsg();
      });
  };

export const resondToLeaveRequests = (obj, roles) => (dispatch) => {
  dispatch(setIsLoadingState({ isLoading: true }));
  axios
    .put(`${BASE_URL}/api/ask_for_leaves/response`, obj)
    .then((res) => {
      toast.success("Vous avez répondu a la demande avec succés");
      dispatch(
        treatLeaveItemInLists({
          treatedReceivedLeaveRequests: res.data,
          roles: roles,
        })
      );
      dispatch(setIsLoadingState({ isLoading: false }));
    })
    .catch(() => {
      errorMsg();
    });
};

export const cancelAcceptedLeaveRequest = (id) => (dispatch) => {
  dispatch(setIsLoadingState({ isLoading: true }));
  const obj = {
    finalStatus: "CANCELED",
  };
  axios
    .put(`${BASE_URL}/api/ask_for_leaves/${id}`, obj)
    .then((res) => {
      dispatch(dispatchUser());
      toast.success("Vous avez annulé votre demande avec succés");
      dispatch(setIsLoadingState({ isLoading: false }));
    })
    .catch(() => {
      errorMsg();
    });
};

export const fetchLeaveRequestResume = (data) => (dispatch) => {
  dispatch(setIsErrorState({ isError: null }));
  dispatch(setIsLoadingState({ isLoading: true }));
  const obj = {
    startDate: dateHelpers.formatDate(data.startDate),
    endDate: dateHelpers.formatDate(data.endDate),
    commentary: data.commentary,
    typeOfLeave: data.typeOfLeave,
    user: data.user,
  };
  axios
    .post(`${BASE_URL}/api/ask_for_leaves/check_resume`, obj)
    .then((res) => {
      dispatch(
        populateLeave({
          numberOfWeekendDays: res.data.numberOfWeekendDays,
          duration: res.data.duration,
          numberOfNotCountableDays: res.data.numberOfNotCountableDays,
          numberOfHolidays: res.data.numberOfHolidays,
          notPaidDuration: res.data.notPaidDuration,
        })
      );
      dispatch(setIsLoadingState({ isLoading: false }));
    })
    .catch((err) => {
      dispatch(
        setIsErrorState({ isError: err.response.data["hydra:description"] })
      );
      dispatch(setIsLoadingState({ isLoading: false }));
    });
};

export const selectleave = (state) => {
  return state.Leave;
};

export const selectleavesList = (state) => {
  return state.Leave.leavesList;
};

export const selectNotTreatedReceivedLeaveRequests = (state) => {
  return state.Leave.notTreatedReceivedLeaveRequests;
};

export const selectTreatedReceivedLeaveRequests = (state) => {
  return state.Leave.treatedReceivedLeaveRequests;
};

export const selectMyleavesList = (state) => {
  return state.Leave.myLeavesList;
};

export const selectleaveListByUserId = (state) => {
  return state.Leave.leaveListByUserId;
}

export const selectIsErrorState = (state) => {
  return state.Leave.isError;
};

export const selectIsLoadingState = (state) => {
  return state.Leave.isLoading;
};

export const selectMyTreatedLeaveList = (state) => {
  return state.Leave.myTreatedLeaveList;
};

export const {
  populateLeave,
  setLeaveList,
  unselectLeave,
  unselectLeaveList,
  setIsLoadingState,
  setIsErrorState,
  setIsSuccessState,
  populateMyLeaveList,
  setMyTreatedLeaveList,
  setNotTreatedReceivedLeaveRequests,
  setTreatedReceivedLeaveRequests,
  setLeaveListByUserId,
  treatLeaveItemInLists,
  updateLeaveItemInMyList,
} = LeaveSlice.actions;

export default LeaveSlice.reducer;
