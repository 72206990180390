import React from "react";

import MyDocsTable from "../../components/myDocsTab/MyDocsTable";

import "../../styles/globalStyle.sass";
import "./myDocumentsPage.sass";

const MyDocumentsPage = () => {
  return (
    <div className="MyDocsWrapper">
      <div className="title h1-Montserrat">Vos documents</div>
      <div className="tablePending">
        <MyDocsTable />
      </div>
    </div>
  );
};

export default MyDocumentsPage;
