/**
 * BuildFilters function handle values of type `array`, `number` and `string`.
 */
export const buildFilters = (filters = {}) =>
  Object.keys(filters).reduce((filterQuery, filterKey, filterIndex) => {
    filterQuery += filterIndex === 0 ? "?" : "&";
    filterQuery += Array.isArray(filters[filterKey])
      ? filters[filterKey].map((value) => `${filterKey}=${value}`).join("&")
      : `${filterKey}=${filters[filterKey]}`;
    return filterQuery;
  }, "");
