import React, { useEffect, useRef, useState } from "react";
import NotificationItem from "../notificationList/NotificationItem";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  fetchNotSeenNotifications,
  resetNotificationCounter,
  selectMercureHubUrl,
  selectNotificationsList,
} from "./../../redux/slice/NotificationSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import {
  BASE_URL,
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
  MERCURE_TOKEN,
} from "./../../services/utils/apiRessources";
import { EventSourcePolyfill } from "event-source-polyfill";

import "./notificationIcon.sass";

const NotificationIcon = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const userId = useSelector(selectUserId);
  const notificationList = useSelector(selectNotificationsList);
  const mercureHubUrl = useSelector(selectMercureHubUrl);

  const useOutsideClick = (callback) => {
    const ref = useRef();
    React.useEffect(() => {
      const handleClick = () => {
        callback();
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [callback]);

    return ref;
  };

  const toggleNotificationList = () => {
    setIsOpen(!isOpen);
    dispatch(resetNotificationCounter());
  };

  const closeNotificationList = () => {
    if (isOpen) setIsOpen(false);
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchNotSeenNotifications(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (mercureHubUrl && userId) {
      const hub = new URL(mercureHubUrl);
      hub.searchParams.append('topic', `${BASE_URL}/api/notifications/{id}`);

      const eventSource = new EventSourcePolyfill(
        hub,
        {
          headers: {
            Authorization: "Bearer " + MERCURE_TOKEN,
          },
        }
      );
      eventSource.onmessage = (event) => {
        const notification = JSON.parse(event.data);
        if (notification.receiver.id === userId) {
          dispatch(addNotification(notification));
        }
      };
    }
  }, [userId, dispatch, mercureHubUrl]);

  const reference = useOutsideClick(closeNotificationList);

  return (
    <div className="notification-icon" ref={reference}>
      <div
        onClick={() => {
          closeNotificationList();
        }}
        className={isOpen ? "blocker" : ""}
      ></div>
      <div
        onClick={() => {
          toggleNotificationList();
        }}
        className="icon"
      >
        {notificationList[HYDRA_TOTALITEMS] > 0 && (
          <span className="counter">{notificationList[HYDRA_TOTALITEMS]}</span>
        )}
        <svg
          width="18"
          height="24"
          viewBox="0 0 18 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
        >
          <path
            d="M15.0001 15.2266V6.67656C15.0001 3.52656 12.4501 0.976562 9.375 0.976562H9C5.7 0.976562 3 3.67656 3 7.05144V15.2263C1.575 16.1263 0.75 17.7012 0.75 19.5763V19.7263C0.75 20.1763 1.05 20.4763 1.5 20.4763H6.9C6.825 20.7013 6.75 20.9263 6.75 21.2263C6.75 22.5013 7.725 23.4763 9 23.4763C10.275 23.4763 11.25 22.5013 11.25 21.2263C11.25 20.9263 11.175 20.7013 11.1 20.4763H16.5C16.95 20.4763 17.25 20.1763 17.25 19.7263V19.5013C17.25 17.7013 16.425 16.1264 15 15.2264L15.0001 15.2266ZM9.75012 21.2266C9.75012 21.6766 9.45012 21.9766 9.00012 21.9766C8.55012 21.9766 8.25012 21.6766 8.25012 21.2266C8.25012 20.7766 8.55012 20.4766 9.00012 20.4766C9.45012 20.4766 9.75012 20.7766 9.75012 21.2266ZM2.25012 18.9766C2.40012 17.7766 3.00012 16.8766 4.05012 16.2766C4.27512 16.1266 4.42512 15.9016 4.42512 15.6016V7.05156C4.50012 4.50156 6.52512 2.47668 9 2.47668H9.375C11.625 2.47668 13.5001 4.35168 13.5001 6.67668V15.6767C13.5001 15.9767 13.6501 16.2017 13.8751 16.3517C14.9251 16.8767 15.5251 17.8517 15.6751 19.0517H2.25L2.25012 18.9766Z"
            fill="#737375"
          />
        </svg>
      </div>
      <div className={isOpen ? "notificationList" : "notificationList display"}>
        <div className="notoficationPopUp_header">
          <h6>Notifications</h6>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              closeNotificationList();
            }}
          />
        </div>
        {notificationList[HYDRA_MEMBER]?.length > 0 ? (
          <div className="notificationPopUp_content">
            {notificationList[HYDRA_MEMBER]?.map((obj, key) => (
              <NotificationItem obj={obj} key={key} />
            ))}
          </div>
        ) : (
          <div className="alert alert-light" role="alert">
            Vous n'avez pas des notifications
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationIcon;
