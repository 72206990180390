const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

const formatDate = (date) => {
  const fullDate = [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");

  const time = [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
  ].join(":");

  return `${fullDate}T${time}`;
};

const getTimeFromDate = (date, formated = false) => {
  const formatedDate = formated ? date : formatDate(date);
  const time = formatedDate.split("T")[1];
  return `${parseInt(time.split(":")[0])}:${time.split(":")[1]}`;
};

const formatDateToFrenchFormat = (dateStr) => {
  return `${dateStr.split("-")[2]}/${dateStr.split("-")[1]}/${
    dateStr.split("-")[0]
  }`;
};

const isBeforeMidDay = (dateStr, endDate = false) => {
  return parseInt(dateStr) < (endDate ? 12 : 10);
};

export const dateHelpers = {
  formatDate,
  formatDateToFrenchFormat,
  isBeforeMidDay,
  getTimeFromDate,
};
