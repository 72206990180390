import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";
import { useDispatch, useSelector } from "react-redux";
import { selectTypesOfleave } from "../../redux/slice/TypeOfLeaveSlice";
import { sendLeaveForUser } from "../../redux/slice/LeaveSlice";

registerLocale("fr", fr);
const AddLeaveForUserForm = ({ user }) => {
  const dispatch = useDispatch();
  const listOfTypeOfLeave = useSelector(selectTypesOfleave);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState("matin");
  const [endtDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState("apres_midi");
  const [leaveData, setLeaveData] = useState({
    typeOfLeave: "",
    startDate: new Date(),
    endDate: new Date(),
    user: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveData({ ...leaveData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (startTime === "matin") {
      startDate.setHours(1, 0, 0);
    }
    if (startTime === "apres_midi") {
      startDate.setHours(12, 0, 0);
    }
    if (endTime === "matin") {
      endtDate.setHours(12, 0, 0);
    }
    if (endTime === "apres_midi") {
      endtDate.setHours(23, 59, 0);
    }
    const data = {
      ...leaveData,
      user: user.id,
      startDate: startDate,
      endDate: endtDate,
    };

    dispatch(sendLeaveForUser(data));
  };

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {user.firstName}&nbsp;{user.lastName}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <div className="form">
            <div className="leave-row-container">
              <label>Type de congé</label>
              <div>
                <select
                  name="typeOfLeave"
                  id="typeOfLeave"
                  value={leaveData.typeOfLeave}
                  className="form-select"
                  required
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Sélectionnez un type de congé</option>
                  {listOfTypeOfLeave?.map((obj, key) => (
                    <option
                      key={key}
                      value={obj.id}
                    >
                      {obj.type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="leave-row-container">
              <label>Date de Début</label>
              <div className="date-selecter-wrapper">
                <div>
                  <DatePicker
                    className="form-control"
                    name="birthdayDate"
                    selected={startDate}
                    closeOnScroll={(e) => e.target === document}
                    dateFormat={"dd/MM/yyyy"}
                    locale={"fr"}
                    onChange={(date) => {
                      setStartDate(date);
                      date.setHours(12, 0, 0);
                      setEndDate(new Date(date));
                    }}
                  />
                </div>

                <select
                  className="form-select"
                  onChange={(e) => {
                    setStartTime(e.currentTarget.value);
                  }}
                >
                  <option value="matin">Journée entière</option>
                  <option value="matin">Matin</option>
                  <option value="apres_midi">Après midi</option>
                </select>
              </div>
            </div>
            <div className="leave-row-container">
              <label>Date de fin</label>
              <div className="date-selecter-wrapper">
                <div>
                  <DatePicker
                    className="form-control"
                    selected={endtDate}
                    closeOnScroll={(e) => e.target === document}
                    dateFormat={"dd/MM/yyyy"}
                    locale={"fr"}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                  />
                </div>
                <select
                  className="form-select"
                  onChange={(e) => {
                    setEndTime(e.currentTarget.value);
                  }}
                >
                  <option value="matin">Journée entière</option>
                  <option value="matin">Matin</option>
                  <option value="apres_midi">Après midi</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Annuler
          </button>
          <button
            type="Button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
            disabled={leaveData.typeOfLeave === ""}
            onClick={(e) => {
              handleFormSubmit(e);
            }}
          >
            Ajouter un congé
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddLeaveForUserForm;
