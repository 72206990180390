import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthorizationValidation from "../components/authorizationValidation/AuthorizationValidation";
import LeaveValidation from "../components/leaveValidation/LeaveValidation";
import PrivateRoute from "./auth/PrivateRoute";
import DocumentValidation from '../components/documentsValidation/documentValidation';

const ValidationsRoute = () => {
  return (
    <Routes>
      <Route
        path="/leave-validations"
        element={
          <PrivateRoute>
            <LeaveValidation />
          </PrivateRoute>
        }
      />
      <Route
        path="/authorization-validations"
        element={
          <PrivateRoute>
            <AuthorizationValidation />
          </PrivateRoute>
        }
      />
      <Route
        path="/document-validations"
        element={
          <PrivateRoute>
            <DocumentValidation />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default ValidationsRoute;
