import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyAuthorizationList,
  fetchMyTreatedAuthorizationRequests,
  selectIsLoadingState,
  selectMyAuthorizationList,
  selectMyTreatedAuthorizationList,
} from "../../redux/slice/AuthorizationSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import MyAuthorizationListTable from "./MyAuthorizationListTable";
import MyTreadtedAuthorizationListTable from "./MyTreadtedAuthorizationListTable";

const MyAuthorizations = () => {
  const dispatch = useDispatch();
  const myAuthorizationList = useSelector(selectMyAuthorizationList);
  const myTreatedAuthorizationList = useSelector(selectMyTreatedAuthorizationList);
  const isLoading = useSelector(selectIsLoadingState);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    dispatch(
      fetchMyAuthorizationList({
        "user.id": userId,
        page: 1,
        finalStatus: "IN_PROGRESS",
      })
    );
    dispatch(fetchMyTreatedAuthorizationRequests(userId));
  }, [dispatch, userId]);

  return (
    <div className="myLeaves-wrapper">
      <label>Demandes en cours</label>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      ) : (
        <MyAuthorizationListTable myAuthorizationList={myAuthorizationList} />
      )}

      <label>Demandes traitées</label>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      ) : (
        <MyTreadtedAuthorizationListTable myTreatedAuthorizationList={myTreatedAuthorizationList} />
      )}
    </div>
  );
};

export default MyAuthorizations;
