import React from "react";
import { dateHelpers } from "../../services/utils/dateHelpers";

const MyAuthorizationListTable = ({ myAuthorizationList }) => {
  return (
    <>
      {myAuthorizationList?.length !== 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">Durée</th>
                <th scope="col">Date</th>
                <th scope="col">Temps</th>
                <th scope="col">État</th>
              </tr>
            </thead>
            <tbody>
              {myAuthorizationList?.map(
                (obj, key) =>
                  key < 5 &&
                  obj.finalStatus === "IN_PROGRESS" && (
                    <tr key={key}>
                      <td>{obj.duration}h</td>
                      <td>
                        {dateHelpers.formatDateToFrenchFormat(
                          obj.startDate.split("T")[0]
                        )}
                      </td>
                      <td>
                        {dateHelpers.getTimeFromDate(obj.startDate, true)}
                        &nbsp;-&nbsp;
                        {dateHelpers.getTimeFromDate(obj.endDate, true)}
                      </td>
                      {obj.finalStatus === "IN_PROGRESS" && (
                        <td className="response_wrapper">
                          <div className="inProgress">En cours</div>
                        </td>
                      )}
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas des demandes en cours
        </div>
      )}
    </>
  );
};

export default MyAuthorizationListTable;
