import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SideBar from "../../components/sideBar/SideBar";
import { dispatchUser } from "../../redux/slice/UserSlice";
import HomeRoute from "../../routes/HomeRoute";
import MenuIcon from "@mui/icons-material/Menu";
import Navbar from "./../../components/navbar/Navbar";
import { selectIsErrorState } from "./../../redux/slice/HolidaySlice";
import { useNavigate } from "react-router-dom";
import { authService } from "./../../services/authService";

import "./homePage.sass";

authService.setup();

const HomePage = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const isError = useSelector(selectIsErrorState);

  useLayoutEffect(() => {
    setTimeout(() => {
      dispatch(dispatchUser());
    }, 500);
  }, [dispatch]);

  useEffect(() => {
    if (isError === true) {
      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
  }, [isError, navigate]);

  const togggleSideBar = () => {
    setIsOpen(!isOpen);
  };

  const closeSideBar = () => {
    setIsOpen(true);
  };

  return (
    <div className="homePage-wrapper">
      <div
        onClick={() => togggleSideBar()}
        className={isOpen ? "menu-icon" : "menu-icon active"}
      >
        <MenuIcon />
      </div>
      <div
        className={isOpen ? "sideBar-wrapper display" : "sideBar-wrapper"}
        onClick={() => closeSideBar()}
      >
        <SideBar />
      </div>
      <div className="content-wrapper" onClick={() => closeSideBar()}>
        <Navbar />
        <HomeRoute />
      </div>
    </div>
  );
};

export default HomePage;
