import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import {
  updateHolidayItem,
  getDaysOff,
  loadDaysOff,
  selectHolidayList,
  selectIsLoadingState,
} from "../../redux/slice/HolidaySlice";
import { HYDRA_MEMBER } from "../../services/utils/apiRessources";
import { dateHelpers } from "./../../services/utils/dateHelpers";
import { HYDRA_TOTALITEMS } from "./../../services/utils/apiRessources";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";
import "./holiday.sass";
import "react-loading-skeleton/dist/skeleton.css";

registerLocale("fr", fr);

const Holiday = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const holidaysList = useSelector(selectHolidayList);
  const isLoading = useSelector(selectIsLoadingState);
  const numberOfPages = Math.ceil(holidaysList[HYDRA_TOTALITEMS] / 10);

  useEffect(() => {
    dispatch(getDaysOff());
  }, [dispatch]);

  return (
    <div className="holidayPage-wrapper">
      <div className="holidayPage-header">
        <h3>Jours fériés</h3>
        <button
          type="button"
          className="btn btn-outline-dark btn-sm"
          onClick={() => dispatch(loadDaysOff())}
        >
          Actualiser
        </button>
      </div>
      <div className="holidayPage-content">
        {isLoading ? (
          <Skeleton count={20} circle={true} />
        ) : (
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">Jour</th>
                <th scope="col">date</th>
                <th scope="col">Payé</th>
              </tr>
            </thead>
            <tbody>
              {holidaysList[HYDRA_MEMBER]?.map((obj, key) => (
                <tr key={key}>
                  <td className="day-column">{obj.day}</td>
                  <td
                    className="changeDate"
                    data-bs-toggle="modal"
                    data-bs-target={`#staticBackdropHoliday${key}`}
                  >
                    {dateHelpers.formatDateToFrenchFormat(
                      obj.startDate.split("T")[0]
                    )}
                  </td>
                  <td className="switch">
                    <Switch
                      checked={obj.paid}
                      onClick={() => {
                        dispatch(
                          updateHolidayItem(obj.id, {
                            isPaid: !obj.paid,
                          })
                        );
                      }}
                    />
                  </td>
                  <td
                    className="modal fade"
                    id={`staticBackdropHoliday${key}`}
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5>Vous voulez Changer la date ?</h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <label>Nouvelle date:</label>
                          <div className="datePicker-wrapper">
                            <DatePicker
                              className="form-control"
                              closeOnScroll={(e) => e.target === document}
                              dateFormat={"dd/MM/yyyy"}
                              selected={selectedDate ?? new Date(new Date(obj.startDate).setHours(1,0,0))}
                              onChange={(date) => setSelectedDate(date)}
                              value={selectedDate ?? new Date(new Date(obj.startDate).setHours(1,0,0))}
                              locale={"fr"}
                            />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            data-bs-dismiss="modal"
                          >
                            Annuler
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              dispatch(
                                updateHolidayItem(obj.id, {
                                  prevDate: obj.startDate,
                                  startDate: selectedDate,
                                })
                              );
                              setSelectedDate(null);
                            }}
                          >
                            Modifier
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {holidaysList[HYDRA_TOTALITEMS] > 10 && (
          <nav aria-label="Page navigation example">
            <ul className="pagination pagination-sm">
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Previous"
                  disabled={currentPage === 1 ? true : false}
                  onClick={() => {
                    dispatch(getDaysOff(currentPage - 1));
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  <span aria-hidden="true">&laquo;</span>
                </button>
              </li>
              {_.times(numberOfPages, (i) => (
                <li className="page-item" key={i}>
                  <button
                    className="page-link"
                    onClick={() => {
                      dispatch(getDaysOff(i + 1));
                      setCurrentPage(i + 1);
                    }}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}
              <li className="page-item">
                <button
                  className="page-link"
                  aria-label="Next"
                  disabled={numberOfPages === currentPage ? true : false}
                  onClick={() => {
                    dispatch(getDaysOff(currentPage + 1));
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  <span aria-hidden="true">&raquo;</span>
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Holiday;
