import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AskForAuthorizationForm from "../components/askForAuthorisationForm/AskForAuthorizationForm";
import AskForLeaveForm from "../components/askForLeaveForm/AskForLeaveForm";
import PrivateRoute from "./auth/PrivateRoute";

const RequestsRoute = () => {
  return (
    <Routes>
      <Route
        path="ask-for-leave"
        element={
          <PrivateRoute>
            <AskForLeaveForm />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Navigate to="/" />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default RequestsRoute;
