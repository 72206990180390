import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";
import { toast } from "react-toastify";
import { BASE_URL } from "./../../services/utils/apiRessources";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import {
  dispatchUser,
  selectUserEmail,
  selectUserFirstName,
  selectUserLastName,
  updateUser,
  selectUserId,
  selectUserPositions,
  updateUserPassword,
  selectIsLoadingState,
  uploadUserImg,
} from "../../redux/slice/UserSlice";
import { fetchTeamsList, selectTeamsList } from "../../redux/slice/teamsSlice";
import TeamPositionDropdown from "../../components/teamPositionDropDown/TeamPositionDropdown";
import { selectUserImg } from "./../../redux/slice/UserSlice";

import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../styles/globalStyle.sass";
import "./myAccount.sass";

const MyAccount = () => {
  const dispatch = useDispatch();
  const userFirstName = useSelector(selectUserFirstName);
  const userLastName = useSelector(selectUserLastName);
  const userEmail = useSelector(selectUserEmail);
  const teams = useSelector(selectTeamsList);
  const positions = useSelector(selectUserPositions);
  const userId = useSelector(selectUserId);
  const isLoading = useSelector(selectIsLoadingState);
  const userImg = useSelector(selectUserImg);

  const [display, setDisplay] = useState(false);
  const [selections, setSelections] = useState([{ team: "", position: "" }]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const initials = `${firstName[0] || " "}${lastName[0] || " "}`
    .toString()
    .toUpperCase()
    .trim();

  useEffect(() => {
    dispatch(fetchTeamsList());
  }, [dispatch]);

  useEffect(() => {
    if (userFirstName) {
      setFirstName(userFirstName);
    }
    if (userLastName) {
      setLastName(userLastName);
    }
  }, [userFirstName, userLastName]);

  useEffect(() => {
    if (positions.length > 0) {
      setSelections(
        positions.map((positionItem) => ({
          team: positionItem.team.id,
          position: positionItem.id,
        }))
      );
    } else if (userEmail === "") {
      dispatch(dispatchUser());
    }
  }, [dispatch, positions, userEmail]);

  const handleSelectionAdd = () => {
    const limit = 2;
    if (selections.length < limit) {
      setSelections([...selections, { team: "", position: "" }]);
    } else {
      toast.warning(
        `c'est la limite! Juste ${limit} entrées sont acceptées à présent`
      );
    }
  };

  const handleSelectionRemove = (selectionIndex) => {
    setSelections((list) =>
      list.filter((_selection, index) => index !== selectionIndex)
    );
  };

  const handleInputChange = (index) => (event) => {
    const { name, value } = event.target;

    setSelections((selectedRows) =>
      selectedRows.map((el, selectionIndex) =>
        selectionIndex === index
          ? {
              ...el,
              [name]: value,
            }
          : el
      )
    );
  };

  const handlePasswordsInputChange = (e) => {
    setconfirmPasswordError(false);
    const { name, value } = e.currentTarget;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleSubmitProfileInfo = () => {
    if (selections.find((selection) => selection.position === "")) {
      toast.warning("SVP, veuillez sélectionner une poste !");
      return;
    }
    if (!firstName) {
      toast.warning("le prénom ne peut être vide !");
      return;
    }
    if (!lastName) {
      toast.warning("le nom ne peut être vide !");
      return;
    }

    dispatch(
      updateUser(userId, {
        firstName: firstName,
        lastName: lastName,
        positions: selections.map((selection) => parseInt(selection.position)),
      })
    );
  };

  const handleChangePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwords.newPassword === passwords.confirmPassword) {
      setconfirmPasswordError(false);
      const obj = {
        oldPassword: passwords.oldPassword,
        confirmPassword: passwords.confirmPassword,
      };
      dispatch(updateUserPassword(userId, obj));
    } else {
      setconfirmPasswordError(true);
    }
  };

  const uploadImg = (data) => {
    if(['image/png', 'image/jpeg', 'image/jpg'].includes(data.type)){
      const formData = new FormData();
      formData.append("file", data);
      dispatch(uploadUserImg(userId, formData));
    } else {
      toast.error("Veuillez entrer un format de fichier valide [.png, jpg ou .jpeg].");
    }
  };

  return (
    <div className="bigColumn">
      <div className="generalProfileInfoDiv">
        <div className="LeftTitle">
          <div className="h6-Montserrat">Informations sur mon profil</div>
          <div className="label-Montserrat">
            Mettez à jour les informations de votre compte.
          </div>
        </div>
        <div className="profileInfoBox">
          <div className="avatarCircle">
            {!userImg ? (
              <Avatar
                label={initials}
                className="mr-2"
                size="xlarge"
                shape="circle"
              />
            ) : (
              <Avatar
                image={`${BASE_URL}${userImg.fileUrl}`}
                className="mr-2"
                size="xlarge"
                shape="circle"
              />
            )}
            <div
              className="upload"
              onMouseEnter={() => setDisplay(true)}
              onMouseLeave={() => setDisplay(false)}
            >
              {display && (
                <label
                  htmlFor="upload"
                  className="upload-file-button"
                >
                  <CameraAltIcon />
                </label>
              )}
            </div>

            <div>
              <input
                type="file"
                id="upload"
                hidden
                onChange={(img) => uploadImg(img.target?.files[0])}
              />
            </div>
          </div>
          <div className="myAccountFormGroups">
            <div className="h6-Montserrat">Prénom</div>
            <input
              required
              type="text"
              name="firstName"
              id="firstName"
              className="form-control"
              placeholder="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="myAccountFormGroups">
            <div className="h6-Montserrat">Nom</div>
            <input
              required
              type="text"
              name="lastName"
              id="lastName"
              className="form-control"
              placeholder="Nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="myAccountFormGroups">
            <div className="h6-Montserrat">Email</div>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control"
              value={userEmail}
              placeholder="Email"
              disabled
              required
            />
          </div>
          <div className="teamAndPos">
            {selections.map((selectedTeamPosition, index) => (
              <TeamPositionDropdown
                key={index}
                index={index}
                selectedTeamPosition={selectedTeamPosition}
                selections={selections}
                teams={teams}
                handleInputChange={handleInputChange(index)}
                handleSelectionRemove={handleSelectionRemove}
              />
            ))}
          </div>
          <div>
            <div className="updateProfileButtons">
              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={handleSubmitProfileInfo}
              >
                Enregistrer
              </button>
              <button
                type="button"
                className="btn btn-outline-primary rounded-circle mt-3"
                style={{
                  width: 40,
                  height: 40,
                }}
                onClick={() => handleSelectionAdd()}
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <div className="changePasswordDiv">
        <div className="LeftTitle">
          <div className="h6-Montserrat">Mettre à jour le mot de passe</div>
          <div className="label-Montserrat">
            Assurez-vous que votre compte utilise un mot de <br />
            passe long et aléatoire pour rester en sécurité{" "}
          </div>
        </div>
        <div className="changePasswordBox">
          <div className="changePasswordGroup">
            <div className="h6-Montserrat">Mot de passe actuel</div>
            <input
              type="password"
              name="oldPassword"
              id="oldPassword"
              className="form-control"
              onChange={handlePasswordsInputChange}
              value={passwords.oldPassword}
              required
            />
          </div>

          <div className="changePasswordGroup">
            <div className="h6-Montserrat">Nouveau mot de passe</div>
            <input
              type="password"
              name="newPassword"
              id="newPassword"
              className="form-control"
              onChange={handlePasswordsInputChange}
              value={passwords.newPassword}
              required
            />
          </div>

          <div className="changePasswordGroup">
            <div className="h6-Montserrat">Confirmez le mot de passe</div>
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              className="form-control"
              onChange={handlePasswordsInputChange}
              value={passwords.confirmPassword}
              required
            />
          </div>
          <div className="buttonAndLoad">
            <div className="passwordSubmitButton">
              <button
                onClick={handleChangePasswordSubmit}
                type="button"
                className="btn btn-primary mt-3"
              >
                Enregistrer
              </button>
            </div>
            <div className="passwordLoader">
              {isLoading && (
                <div
                  className="spinner-border text-primary mt-3"
                  role="status"
                />
              )}
            </div>
          </div>

          <div className="changePasswordGroup">
            {confirmPasswordError === true ? (
              <div className="alert alert-danger" role="alert">
                Merci de Vérifier la conformité de votre mot de passe.
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
