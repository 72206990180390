import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/utils/apiRessources";

const initialState = {
  id: null,
  type: null,
  numberOfAllowsDays: null,
  typeOfLeaveList: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const TypeOfLeaveSlice = createSlice({
  name: "typeOfLeave",
  initialState,
  reducers: {
    populateTypeOfLeave: (state, action) => {
      state.id = action.payload.id;
      state.type = action.payload.type;
      state.numberOfAllowsDays = action.payload.numberOfAllowsDay;
    },
    populateTypeOfLeaveList: (state, action) => {
      state.typeOfLeaveList = action.payload.typeOfLeaveList;
    },
    unselectTypeOfLeave: (state, _action) => {
      state.id = null;
      state.type = null;
      state.numberOfAllowsDays = null;
    },
    addItemToTypeOfLeaveList: (state, action) => {
      state.typeOfLeaveList.push(action.payload);
    },
    removeItemFromTypeOfLeaveList: (state, action) => {
      const index = state.typeOfLeaveList.findIndex(
        (item) => item.id === action.payload
      );

      if (index !== -1) {
        state.typeOfLeaveList.splice(index, 1);
      }
    },
    updateElementIntypeOfLeaveList: (state, action) => {
      const index = state.typeOfLeaveList.findIndex(
        (item) => item.id === action.payload.id
      );
      if(index !== -1){
        state.typeOfLeaveList[index] = action.payload;
      }
    },
    unselectTypeOfLeaveList: (state, _action) => {
      state.typeOfLeaveList = null;
    },
    setIsLoadingState: (state, action) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsErrorState: (state, action) => {
      state.isError = action.payload.isError;
    },
    setIsSuccessState: (state, action) => {
      state.isSuccess = action.payload.isSuccess;
    },
  },
});

export const fetchtypesOfLeave=(isEnable=false) => (dispatch) => {
  const param = isEnable ? "?isEnable=1" : "";
  axios
    .get(`${BASE_URL}/api/type_of_leaves${param}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((res) => {
      dispatch(populateTypeOfLeaveList({ typeOfLeaveList: res.data }));
    })
    .catch(() => {
      toast.error("Une erreur s'est produite lors de du chargement des types de congé");
    });
};

export const changeIsEnableState=(data,id) => (dispatch) => {
  axios
    .put(`${BASE_URL}/api/type_of_leaves/${id}`,data)
    .then((res) => {
      toast.success("Vous avez changé le type de congé avec succès");
      dispatch(updateElementIntypeOfLeaveList(res.data));
    })
    .catch(() => {
      toast.error("Une erreur s'est produite lors de du changement d'un type de congé");
    });
};

export const deleteTypeOfLeave = (id) => (dispatch) => {
  axios
    .delete(`${BASE_URL}/api/type_of_leaves/${id}`)
    .then(() => {
      toast.success("Vous avez supprimé un type de congé avec succéss");
      dispatch(removeItemFromTypeOfLeaveList(id));
    })
    .catch(() => {
      toast.error(
        "Une erreur s'est produite lors de la suppression d'un type de congé"
      );
    });
};

export const addTypeOfLeave = (data) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/type_of_leaves`, data)
    .then((res) => {
      toast.success("Vous avez ajouté un type de congé avec succés");
      dispatch(addItemToTypeOfLeaveList(res.data));
    })
    .catch(() => {
      toast.error(
        "Une erreur s'est produite lors de l'ajout d'un type de congé"
      );
    });
};

export const selectTypesOfleave = (state) => {
  return state.typeOfLeave.typeOfLeaveList;
};

export const {
  populateTypeOfLeave,
  populateTypeOfLeaveList,
  unselectTypeOfLeave,
  unselectTypeOfLeaveList,
  setIsLoadingState,
  setIsErrorState,
  setIsSuccessState,
  addItemToTypeOfLeaveList,
  removeItemFromTypeOfLeaveList,
  updateElementIntypeOfLeaveList
} = TypeOfLeaveSlice.actions;

export default TypeOfLeaveSlice.reducer;
