import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import TeamPositionDropdown from "../../components/teamPositionDropDown/TeamPositionDropdown";
import {
  updateUser,
  selectUserId,
  selectUserPositions,
  selectIsSuccessState,
} from "../../redux/slice/UserSlice";
import {
  fetchTeamsList,
  selectTeamsList,
} from "../../redux/slice/teamsSlice";

import "bootstrap/dist/css/bootstrap.css";
import "./firstLogin.sass";

const FirstLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const teams = useSelector(selectTeamsList);
  const positions = useSelector(selectUserPositions);
  const isSuccess = useSelector(selectIsSuccessState);

  const [selections, setSelections] = useState([{ team: "", position: "" }]);

  useEffect(() => {
    if (positions?.length > 0) {
      navigate('/')
    }
  }, [dispatch, positions, navigate]);

  useEffect(() => {
    dispatch(fetchTeamsList());
  }, [dispatch])

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
  }, [navigate, isSuccess]);

  const handleSubmitPositions = () => {
    if (selections.find(selection => selection.position === '')) {
      toast.warning('SVP, veuillez sélectionner une poste !');
      return;
    }
    dispatch(updateUser(
      userId,
      {
        positions: selections.map(selection => parseInt(selection.position))
      }
    ));
  };

  const handleSelectionAdd = () => {
    const limit = 2;
    if (selections.length < limit) {
      setSelections([...selections, { team: "", position: "" }]);
    } else {
      toast.warning(
        `c'est la limite! Juste ${limit} entrées sont acceptées à présent`
      );
    }
  };

  const handleSelectionRemove = (selectionIndex) => {
    setSelections(list => list.filter((_selection, index) => index !== selectionIndex));
  };

  const handleInputChange = (index) => (event) => {
    const { name, value } = event.target;

    setSelections((selectedRows) =>
      selectedRows.map((el, selectionIndex) =>
        selectionIndex === index ? {
          ...el,
          [name]: value
        } : el
      )
    );
  };

  return (
    <div className="firstPage">
      <div className="upperSpace" />

      <div className="welcomeMessage">
        <h2 className="title welcome">Welcome 🎉</h2>
        <p className="text">
          CosaRH rendra les demandes de congés et de documents plus faciles à
          réaliser
        </p>
      </div>

      <div className="box">
        <div className="instruct">
          <h6 className="title">
            Veuillez indiquer votre équipe et votre position au sein de
            CosaVostra
          </h6>
        </div>

        <div className="selects">
          {selections.map((selectedTeamPosition, index) =>
            <TeamPositionDropdown
              key={index}
              index={index}
              selectedTeamPosition={selectedTeamPosition}
              selections={selections}
              teams={teams}
              handleInputChange={handleInputChange(index)}
              handleSelectionRemove={handleSelectionRemove}
            />
          )}
        </div>
        <div className="buttons">
          <div className="btnLeft">
            <Button onClick={() => handleSelectionAdd()} variant="outlined">
              +Plus
            </Button>
          </div>
          <div className="btnLeft">
            <Button
              onClick={handleSubmitPositions}
              variant="contained"
              disableElevation
            >
              Accéder
            </Button>
          </div>
        </div>
      </div>

      <div className="bottomSpace"></div>
    </div>
  );
};

export default FirstLoginPage;
