import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./userInformationsPopup.sass";
import UserInformationsCard from "./UserInformationsCard";
const UserInformationsPopup = ({ user }) => {
  const [displayPopup, setDisplayPopup] = useState(false);


  return (
    <div className="info-icon-wrapper">
      <div
        className="info-icon"
        onMouseEnter={() => setDisplayPopup(true)}
        onMouseLeave={() => setDisplayPopup(false)}
      >
        <InfoOutlinedIcon color="disabled" />
      </div>
      {displayPopup && (
        <UserInformationsCard user={user} />
      )}
    </div>
  );
};

export default UserInformationsPopup;
