import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import CosaLogo from "../../assets/CosaLogo";
import {
  dispatchChangePassword,
  selectIsErrorState,
  selectIsLoadingState,
  selectIsSuccessState,
} from "../../redux/slice/UserSlice";

import "./changePasswordPage.sass";

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { key } = useParams();

  const isLoadingState = useSelector(selectIsLoadingState);
  const isErrorState = useSelector(selectIsErrorState);
  const isSuccessState = useSelector(selectIsSuccessState);

  const [loader, setLoader] = useState(false);
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setconfirmPasswordError(false);
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (credentials.password === credentials.confirmPassword) {
      setconfirmPasswordError(false);
      const obj = {
        password: credentials.password,
        token: key,
      };
      dispatch(dispatchChangePassword(obj));
    } else {
      setconfirmPasswordError(true);
    }
  };

  useEffect(() => {
    setLoader(isLoadingState);
  }, [isLoadingState]);

  useEffect(() => {
    if (isErrorState) {
      toast.error("Votre session est expirée");
      navigate("/login");
    }

    if (isSuccessState) {
      toast.success("Votre mot de passe a été modifié avec succès");
      navigate("/login");
    }
  }, [isErrorState, isSuccessState, navigate]);

  return (
    <>
      <div className="changePassword">
        <div
          className="logo_wrapper"
          onClick={() => {
            navigate("/login");
          }}
        >
          <CosaLogo></CosaLogo>
          <div className="logo_text">
            <h1>CosaRH</h1>
            <div>Made with ❤️ by CosaVostra</div>
          </div>
        </div>
        <div className="changePassword-form-card">
          <div className="card">
            <div className="card-body p-3">
              <div className="login_form_wrapper">
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3 password_wrapper">
                    <label className="form-label d-flex justify-content-start">
                      Nouveau mot de passe
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="changePassword"
                      className="form-control"
                      aria-describedby="passwordHelp"
                      onChange={handleChange}
                      value={credentials.password}
                      required
                    />
                  </div>
                  <div className="mb-3 password_wrapper">
                    <label className="d-flex justify-content-start form-label">
                      Confirmation du mot de passe
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      id="confirmChangePassword"
                      className="form-control"
                      onChange={handleChange}
                      value={credentials.confirmPassword}
                      required
                    />
                  </div>
                  <div className="login_auth_wrapper">
                    <button className="reset-password-button">Valider</button>
                  </div>
                </form>
                {confirmPasswordError === true ? (
                  <div className="alert alert-danger mt-2" role="alert">
                    Merci de Vérifier la conformité de votre mot de passe.
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {loader ? (
                <div
                  className="spinner-border text-primary mt-3"
                  role="status"
                ></div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPage;
