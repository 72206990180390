import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  addTypeOfLeave,
  changeIsEnableState,
  deleteTypeOfLeave,
  fetchtypesOfLeave,
  selectTypesOfleave,
} from "./../../redux/slice/TypeOfLeaveSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Switch from "@mui/material/Switch";

import "./typeOfLeave.sass";

const TypeOfLeave = () => {
  const dispatch = useDispatch();
  const typeOfLeaveList = useSelector(selectTypesOfleave);
  const [credentials, setCredentials] = useState({
    type: "",
    numberOfAllowsDays: 0,
  });

  useEffect(() => {
    dispatch(fetchtypesOfLeave(false));
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      type: credentials.type,
      numberOfAllowsDays: parseInt(credentials.numberOfAllowsDays),
    };
    dispatch(addTypeOfLeave(data));
    setCredentials({
      type: "",
      numberOfAllowsDays: 0,
    });
  };

  return (
    <div className="TypeOfLeave-wrapper">
      <div className="TypeOfLeave-form">
        <div className="form">
          <div className="mb-3">
            <label>1. Type de congé</label>
          </div>
          <input
            name="type"
            type="text"
            id="type"
            className="form-control mb-3"
            placeholder="Type de congé"
            onChange={(e) => {
              handleChange(e);
            }}
            value={credentials.type}
          />
          <div className="mb-3">
            <label>
              2. Nombre de jours non calculé
            </label>
          </div>
          <input
            name="numberOfAllowsDays"
            id="numberOfAllowsDays"
            type="number"
            className="form-control mb-3"
            onChange={(e) => {
              handleChange(e);
            }}
            value={credentials.numberOfAllowsDays}
          />
          <button
            className={`btn ${credentials.type.length > 1 ? "btn-primary" : "btn-secondary"}`}
            data-bs-toggle="modal"
            data-bs-target="#addTypeOfLeave"
            disabled={credentials.type.length < 1}
          >
            Ajouter un Type de congé
          </button>
        </div>
      </div>
      <div className="TypeOfLeave-list">
        <table className="table  table-borderless">
          <thead>
            <tr>
              <th scope="col">Type</th>
              <th scope="col">Jours non calculés</th>
              <th scope="col">Activé</th>
              <th scope="col">Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {typeOfLeaveList?.map((obj, key) => (
              <tr key={key}>
                <td>{obj.type}</td>
                <td>{obj.numberOfAllowsDays}</td>
                <td>
                  <Switch
                    checked={obj.isEnable}
                    onClick={() => {
                      dispatch(
                        changeIsEnableState({ isEnable: !obj.isEnable },obj.id)
                      );
                    }}
                  />
                </td>
                <td
                  className="deleteIcon"
                  data-bs-toggle="modal"
                  data-bs-target={`#deleteTypeOfLeave${obj.id}`}
                >
                  <DeleteForeverIcon />
                </td>
                <td
                  className="modal fade"
                  id={`deleteTypeOfLeave${obj.id}`}
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <h6>Vous êtes sûr de vouloir supprimer ce type de congé ? </h6>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          data-bs-dismiss="modal"
                        >
                          Annuler
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            dispatch(deleteTypeOfLeave(obj.id));
                          }}
                        >
                          Supprimer
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        className="modal fade"
        id="addTypeOfLeave"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h6>Vous étes sur d'ajouter un type de congé ?</h6>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  handleFormSubmit(e);
                }}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeOfLeave;
