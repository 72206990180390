import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import moment from "moment";
import {
  fetchMyTreatedleaveRequests,
} from "../../redux/slice/LeaveSlice";
import { selectUserId } from "../../redux/slice/UserSlice";
import Paginator from "../paginator/Paginator";

const MyTreatedLeaveListTable = ({ myTreatedLeavesList }) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);


  const clickPagination = (page) => {
    dispatch(fetchMyTreatedleaveRequests(userId, page));
  };

  return (
    <>
      {myTreatedLeavesList[HYDRA_MEMBER]?.length !== 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Date</th>
                <th scope="col">Durée</th>
                <th scope="col">Etat</th>
              </tr>
            </thead>
            <tbody>
              {myTreatedLeavesList[HYDRA_MEMBER]?.map((obj, key) => (
                <tr key={key}>
                  <td>{obj.typeOfLeave.type}</td>
                  <td>
                    {`${dateHelpers.formatDateToFrenchFormat(
                      obj.startDate.split("T")[0]
                    )} - ${dateHelpers.formatDateToFrenchFormat(
                      obj.endDate.split("T")[0]
                    )}`}
                  </td>
                  <td>{obj.notPaidDuration}j</td>
                  {obj.finalStatus === "ACCEPTED" &&
                    (moment(obj.startDate.split("T")[0]).isAfter() ? (
                      <td className="response_wrapper">
                        <div
                          className="accepted_cancel"
                          data-bs-toggle="modal"
                          data-bs-target={`#staticBackdrop${key}1`}
                        >
                          Acceptée
                        </div>
                      </td>
                    ) : (
                      <td className="response_wrapper">
                        <div className="accepted">Accepté</div>
                      </td>
                    ))}
                  {obj.finalStatus === "REJECTED" && (
                    <td className="response_wrapper">
                      <div className="rejected">Refusée</div>
                    </td>
                  )}
                  {obj.finalStatus === "CANCELED" && (
                    <td className="response_wrapper">
                      <div className="rejected">Annulée</div>
                    </td>
                  )}
                  <td
                    className="modal fade"
                    id={`staticBackdrop${key}1`}
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <h6>Veuillez contacter votre manager pour valider votre annulation de demande de congé ☺️</h6>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            totalItems={myTreatedLeavesList[HYDRA_TOTALITEMS]}
            paginate={clickPagination}
          />
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas encore des demandes traitées
        </div>
      )}
    </>
  );
};

export default MyTreatedLeaveListTable;
